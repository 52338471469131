import React, { Component } from "react";
import creditCardIcon from "../../assets/images/icons/credit_cards.png";
import officePaymentIcon from "../../assets/images/icons/office.png";
import { CREDIT_CARD, CASH, TRAVEL_PAYMENTS } from "../../constants/paymentOptionsIDConstants";

class PaymentOptions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            paymentMethods: [
                { id: CASH, title: "Paga con cash", srcImage: officePaymentIcon },
                { id: TRAVEL_PAYMENTS, title: "Paga con Travel Payments", srcImage: creditCardIcon }
            ],
            optionSelected: CASH
        }
    }

    onChange = (id) => {
        this.setState({
            optionSelected: id
        }, this.props.onChangePaymentOption(id))
    }
    render() {
        return (
            <div className="Payment mt-3 mb-3 ml-auto mr-auto">
                <h1 className="font-secondary text-2xl font-medium mb-3">
                    Elige el método de pago
                </h1>
                <div className="mb-3">
                    <div className="Payment__methods">
                        <div className="row">
                            {this.state.paymentMethods.map(payment => {
                                let height = "75px";
                                let maxHeight = "96px";
                                if(payment.id === CASH) {
                                    height = "73px";
                                }
                                let styles = { height:  height, maxHeight : maxHeight};
                                return (
                                    <div className="col-md-4 col-sm-12 mt-2" key={payment.id}>
                                        <div className={`Payment__method bg-white rounded-sm p-3 text-center ${payment.id === this.state.optionSelected ? "InsurancesPaymentForm_active": ""}`}
                                            onClick={() => this.onChange(payment.id)}
                                            role="button"
                                        >
                                            <div>
                                                <input type="radio"role="button"  checked={payment.id === this.state.optionSelected ? true : false} />
                                                <div className="text-center">
                                                    <img src={payment.srcImage} alt="Payment icon"
                                                        className="ml-auto mr-auto"
                                                        style={styles} />
                                                </div>
                                                <p className={`text-md font-medium font-secondary text-center`}>
                                                    {payment.title}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default PaymentOptions;