import React, { Component } from "react";
import '../../../assets/stylesheets/Booking.css';

class PaymentFrame extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isTest : true
    }
  }
  
  getPaymentProvider(paymentProvider) {
    if (paymentProvider === "102") {
      return "pagoefectivo";
    } else if (paymentProvider === "105" || paymentProvider === "106") {
      return "safetypay";
    } else if(paymentProvider === "124") {
      return "cuotealo"
    } else if(paymentProvider === "125"){
      return "acuotaz"
    }
    return "";
  }

  render() {
    const {paymentProvider, registerRS } = this.props;//url extraido de Booking.jsx
    //let url = "https://gateway.safetypay.com/Express4/Checkout/index?TokenID=c3bd175a-ee2d-4908-ae6f-6733dd82dcdc&ChannelID=ONLINE"
    //let url = "https://payment.pagoefectivo.pe/CA66E2B8-CEA7-4A38-922B-29FE3B43B116.html";
    const className = this.getPaymentProvider(paymentProvider)
    console.log('CLASSNAME!!', className)
    return (
      <div className="PaymentFrame">
        {
          registerRS && registerRS.paymentUrl ?
            <iframe
              className={this.getPaymentProvider(paymentProvider)}
              src={registerRS.paymentUrl}
            ></iframe> : null
        }
      </div>
    )
  }
}

export default PaymentFrame;
