import moment from 'moment';
import cardValidator from 'card-validator';

const validator = {
  isPresent(value) {
    let error;
    let isValid = true;

    if (value.trim() === "") {
      error = {
        message: 'El campo está vacío'
      };
      isValid = false;
    }

    return {
      isValid,
      error
    };
  },

  //AQUI CREAMOS EL IS VALID MESSAGE()

  //USAMOS UN IF SEGUN EL SEGUNDO PARAMETRO PAYMENT CODE
  //122 MANDO A IS VALID BEAN TARJETA OH
  //123 MANDO A IS VALID BEAN SCOTIABANK


  getInputBySlice(bines = []) {

  },

  isEmail(value) {
    let error;
    let isValid = true;

    const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!regex.test(value)) {
      error = {
        message: "Correo inválido"
      };
      isValid = false;
    }

    return {
      isValid,
      error
    };
  },

  isPhoneNumber(value) {
    let error;
    let isValid = true;

    const regex = /^[0-9]*$/;

    if (!regex.test(value)) {
      error = {
        message: "checkout.passengers.validation.number"
      };
      isValid = false;
    }

    return {
      isValid,
      error
    };
  },

  isNumber(value) {
    let error;
    let isValid = true;

    const regex = /^[0-9]*$/;

    if (!regex.test(value)) {
      error = {
        message: "checkout.passengers.validation.number"
      };
      isValid = false;
    }

    return {
      isValid,
      error
    };
  },

  //validacion edad mayores de 70 años para seguro
  isValidInsuranceAge(value) {
    let error;
    let isValid = true;

    let dateToday = moment()//.format('YYYY-MM-DD');
    let dateBirthday = moment(value);

    let aniosDifference = dateToday.diff(dateBirthday, "years")

    if(aniosDifference > 70) {
      isValid = false;
      error = {
        message: "PASAJERO ES MAYOR A 70 AÑOS"
      }
    }

    return {
      error,
      isValid
    }
  },

  isValidDate(value) {
    let error;
    let isValid = true;

    if (!moment(value).isValid() || value.split("-")[2] === "") {
      isValid = false;
      error = {
        message: "checkout.passengers.validation.date"
      }
    }

    return {
      isValid,
      error
    }
  },

  isValidText(value) {
    let error;
    let isValid = true;

    const regex = /^[a-zA-Z\s]*$/;

    if (!regex.test(value)) {
      error = {
        message: "checkout.passengers.validation.clear_input"
      };
      isValid = false;
    }

    return {
      isValid,
      error
    };
  },

  isTwoLastName(value) {
    let isValid = true;
    let error;

    let lastNames = value.trim().split(' ')//separado por espacio
    //lastName.push(value);
    //console.log('lastName array', lastName);

    if(lastNames.length < 2) {
      isValid = false;
      error = {
        message : "Favor de ingresar además de este, el otro apellido"
      };
    }

    return {
      isValid,
      error
    };
  },

  /*isCreditCard(value) {
    let error;
    let isValid = true;

    if (!cardValidator.number(value).isValid) {
      error = {
        message: "checkout.passengers.validation.card"
      };
      isValid = false;
    }

    return {
      isValid,
      error
    };
  },*/

  exactLength(value, length) {
    let error;
    let isValid = true;

    if (value.length !== length) {
      error = {
        message: "CVV inválido"
      };
      isValid = false;
    }

    return {
      isValid,
      error
    };
  },

  minMaxLength(value, minLength, maxLength) {
    let error;
    let isValid = true;

    if (value.length < minLength || value.length > maxLength) {
      error = {
        message: "Caracteres del cvv es menor o excede a la cantidad permitida"
      };
      isValid = false;
    }

    return {
      isValid,
      error
    };
  },

  isExpirationDate(value) {
    let error;
    let isValid = true;

    const regex = /^(0[1-9]|1[0-2])\/([0-9]{2})$/;

    if (!regex.test(value)) {
      error = {
        message: 'Fecha de expiración inválida'
      };
      isValid = false;
    }

    return {
      isValid,
      error
    };
  },

  isCreditCard(value) {
    let error;
    let isValid = true;

    if (!cardValidator.number(value).isValid) {
      error = {
        message: 'Número de tarjeta inválido'
      };
      isValid = false;
    }

    return {
      isValid,
      error
    };
  }

};

export default validator;