import axios from "axios";
import responseInsurances from '../data/insurancesRecommendations.json';
import { 
    SEARCH_INSURANCES_RECOMMENDATIONS,
    SEND_PARAMS_SEARCH,
    SELECT_INSURANCE,
} from "./types";
import {
    BASE_URL
  } from "../config/keys";

const requestConfig = {
    withCredentials: true
};

const config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS"
    }
  };

export const sendParamsToSearch = (data) => dispatch => {
    dispatch({
        type : SEND_PARAMS_SEARCH,
        payload : data
    })
}

export const searchInsurancesRecommendations = (data) =>  async dispatch => {
    const URI = `https://insurance.zdev.tech/api/insurances/search`;
    console.log('URI in searchInsurancesRecommendations -> ', URI);
    console.log('data rq in searchInsurancesRecommendations -> ', data);
    const response = await axios.post(URI, data, config);
    console.log('data rs in searchInsurancesRecommendations -> ', response.data);
    dispatch({
        type : SEARCH_INSURANCES_RECOMMENDATIONS,
        payload : response.data
    })
    
}

export const selectInsurance = (data) => async dispatch => {
    const res = await axios.post(`${BASE_URL}/api/sessions/save?id=${data.sessionID}`, data, requestConfig);
    if (res.status === 200) {
        dispatch({
            type: SELECT_INSURANCE,
            payload: data
        });
        window.location.href = `${window.location.origin}/checkout/${data.sessionID}`;
    }
}
