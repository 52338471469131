import { LOAD_ENGINE } from "../actions/types";

const initialState = {
    engine: null
}

export default function (state = initialState, action) {
    switch (action.type) {
        case LOAD_ENGINE:
            return Object.assign({}, state, action.payload);
        default:
            return state;
    }
}