import React, { Component } from 'react';
import "../../assets/stylesheets/Navbar.css";
import logoUniversal from '../../assets/images/banners/bannerUniversal.png';

class Banner extends Component {
    render() {
        return (
            <header>
                <nav className="navbar pt-0 pb-0">
                    <div className="container">
                        <div className="row w-full">
                            <div className="col">
                                <img
                                    className="logoBanner"
                                    src={logoUniversal}
                                    alt="src logo"
                                    style={{ height: "120px", width: "325%" }}
                                >
                                </img>
                            </div>
                        </div>
                    </div>
                </nav>
            </header>
        )
    }
}

export default Banner;