import axios from 'axios';
import {
    GET_CHECKOUT_DATA
} from './types';
import {
    BASE_URL
  } from "../config/keys";

const requestConfig = {
    withCredentials: true
}

export const getCheckoutData = id => async dispatch => {
    const res = await axios.get(`${BASE_URL}/api/sessions?id=${id}`, requestConfig);
    if (res.data !== "") {
        dispatch({
            type: GET_CHECKOUT_DATA,
            payload: res.data.value
        });
    }
}