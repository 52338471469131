import React, { Component } from 'react';
import { DESTINATION_INDEX_PARAM_INDEX, DESTINATION_PARAM_INDEX, END_DATE_PARAM_INDEX, ORIGIN_PARAM_INDEX, START_DATE_PARAM_INDEX } from '../../constants/searchParamConstants';
import engineUtils from "../../utils/enginesUtils";
import { FaPlaneArrival, FaPlaneDeparture, FaRegCalendarAlt } from 'react-icons/fa';

class BookingDetails extends Component {

    renderInsuranceProductInfo(data) {
        const { searchParams } = data;
        const { product } = data.insurance;
        const total = parseFloat(product.fareProduct.totalToPaid) + parseFloat(data.bookForm.commissionAgency);
        return (
            <div className='BookingDetails__insurance mb-2'>

                <div className='row'>
                    <div className='BookingDetails__insuranceHeader col'>
                        <span className="BookingDetails__insuranceIndicator font-secondary">
                            Detalles del producto elegido
                        </span>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-4 col-12 p-4 mt-auto mb-auto'>
                        <div className='d-flex space-between'>
                            <p className="BookingDetails__locationCode font-secondary mt-0 mb-0">
                                Nombre:
                            </p>
                            <p className='text-gray-600 font-light mt-0 mb-0'>{product.product}</p>
                        </div>
                        <div className='d-flex space-between'>
                            <p className="BookingDetails__locationCode font-secondary mt-0 mb-0">
                                Precio:
                            </p>
                            <p className='text-gray-600 font-light mt-0 mb-0'>USD {total}</p>
                        </div>


                    </div>
                    <div className='col-md-8 col-12 p-0 mt-auto mb-auto'>
                        {this.renderInsuranceItineraryInfo(data)}
                    </div>
                </div>
            </div>)
    }
    renderInsuranceItineraryInfo(data) {
        const { searchParams, bookForm } = data;
        const origin = searchParams[ORIGIN_PARAM_INDEX];
        const destination = engineUtils.getDestinationValueByDestinationIndex(searchParams[DESTINATION_INDEX_PARAM_INDEX]);
        const startDate = searchParams[START_DATE_PARAM_INDEX];
        const endDate = searchParams[END_DATE_PARAM_INDEX];
        return (
            <div className='BookingDetails__insurance'>
                <div className='row'>
                    <div className='col-md-6 col-12 p-3 mt-auto mb-auto InsurancesItineraryDetails'>
                        <div className='d-flex space-between'>
                            <p className="BookingDetails__locationCode font-secondary mt-0 mb-0">
                                <FaPlaneDeparture /> Origen:
                            </p>
                            <p className='text-gray-600 font-light mt-0 mb-0'>{origin}</p>
                        </div>
                        <div className='d-flex space-between'>
                            <p className="BookingDetails__locationCode font-secondary mt-0 mb-0">
                                <FaPlaneArrival /> Destino:
                            </p>
                            <p className='text-gray-600 font-light mt-0 mb-0'>{destination}</p>
                        </div>
                        <div className='d-flex space-between'>
                            <p className="BookingDetails__locationCode font-secondary mt-0 mb-0">
                                <FaRegCalendarAlt />  Fecha de salida:
                            </p>
                            <p className='text-gray-600 font-light mt-0 mb-0'>{startDate}</p>
                        </div>
                        <div className='d-flex space-between'>
                            <p className="BookingDetails__locationCode font-secondary mt-0 mb-0">
                                <FaRegCalendarAlt /> Fecha de retorno:
                            </p>
                            <p className='text-gray-600 font-light mt-0 mb-0'>{endDate}</p>
                        </div>
                    </div>
                    <div className='col-md-6 col-12 p-3 row mt-auto mb-auto'>
                        {bookForm.passengers.map((pax, index) => {
                            return this.renderTraveler(pax, index);
                        })}
                    </div>
                </div>
            </div>)
    }

    renderTraveler(traveler, index) {
        return (
            <div className="font-secondary text-gray-800 w-100 mt-3 d-flex ml-3" key={index}>

                <i className='fas fa-user mt-1'></i>
                <p className='ml-3'>
                    Pasajero {index + 1}: {traveler.firstName} {traveler.lastName}
                </p>

            </div>

        )
    }

    render() {
        const { data } = this.props;
        return (
            <div className='BookingDetails'>
                <div className='BookingDetails__content p-0 pb-2'>
                    <div className='BookingDetails__insurances'>
                        {this.renderInsuranceProductInfo(data)}
                    </div>
                </div>
            </div>
        )
    }
}

export default BookingDetails;