import { combineReducers } from 'redux';
import insurancesReducer from './insurancesReducer';
import checkoutReducer from './checkoutReducer';
import bookFormReducer from './bookFormReducer';
import bookingReducer from './bookingReducer';
import engineReducer from './engineReducer';

export default combineReducers({
    insurancesReducer : insurancesReducer,
    checkoutReducer: checkoutReducer,
    bookFormReducer: bookFormReducer,
    bookingReducer: bookingReducer,
    engineReducer: engineReducer
})