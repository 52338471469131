import React, { Component } from "react";
import bbva from "../../../assets/images/banks/bbva.png";
import bcp from "../../../assets/images/banks/bcp.png";
import interbank from "../../../assets/images/banks/interbank.png";
import scotiabank from "../../../assets/images/banks/scotiabank.png";
import "../../../assets/stylesheets/TravelPayment.css";
import moment from "moment";
import travelPaymentsIcon from '../../../assets/images/payment/travel-payments-icon.png';

class TravelPaymentFrame extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isTest : true
    }
  }
  
  getPaymentProvider(paymentProvider) {
    
  }

  render() {
    console.log('props in TravelPaymentFrame .> ', this.props);
    const { booking, data } = this.props;
    console.log('booking como prop in TravelPaymentFrame -> ', booking);

    return (
      <div className="payment-container" style={{margin: "auto", width: "60%", textAlign: "left", backgroundColor: "white", padding: "20px"}}>
        <div className="col-4 pl-0 pr-0">
          <img 
            src={travelPaymentsIcon}
            title="travelPaymentsIcon"
            alt="travelPaymentsIcon"
            height={"50px"}
            width={100}
            style={{width: "160px"}}
          />
        </div>
        <div className="mt-2 mb-2"><div className="d-flex">Tu número de orden de pago es :</div></div>

        <div className="" style={{backgroundColor: "#2c3e50", color: "white", padding: "10px", textAlign: "center"}}>
          <div><span>Número de pago</span></div>
          <div><span style={{fontSize: "30px"}}>{booking.orderNumber}</span></div>
        </div>
        <div className="row" style={{backgroundColor: "#eeeeee", padding: "10px", margin: "0px"}}>
          <div className="col"><span>Monto total a pagar</span></div>
          <div className="col" style={{textAlign: "right"}}>
            <span><b>{'US$ ' + parseFloat(booking.paymentAmount).toFixed(2)}</b></span>
          </div>
        </div>
        <div className="" style={{backgroundColor: "#e0f7fa", padding: "10px", textAlign: "center"}}>
          <div><span>Recuerda que tienes hasta:</span></div>
          <div>
            <span style={{fontSize: "30px"}}>{moment(booking.ticketingTimeLimit).format("DD/MM/YYYY HH:mm:ss ")}</span>
          </div>
        </div>

        <div className="mt-2 mb-2">
          <div>Tu número de Orden de pago sera procesado cuando hayas realizado el pago, en cualquiera de los siguientes bancos:</div>
        </div>

        <div className="" style={{marginTop: "40px"}}>
            <div className="mb-10 banks">
                <div className="wrapper"><a><div className="wrapper-img"><img src={bbva} alt="bbva"/></div></a></div>
            </div>
            <div className="mb-10 banks-summary">
                <div className="mr-30">
              <div className="summary-bbva step-bbva">
                <ul className="list">
                  <li>Podrás pagar desde tu Banca por Internet BBVA, Terminal Financiero, Saldo Express, Banca por teléfono y App móvil del Banco.</li>
                  <li><strong>No hay costo por pago.</strong></li>                      
                </ul>
              </div>
                </div>
            </div>
            <div className="mb-10 banks-steps">
                <label className="content-title">Instrucciones de pago</label>
                <div className="mr-50">
                  <div className="steps-bbva step-bbva">
                    <ol className="list">
                      <li>Ingresa a tu aplicativo móvil BBVA o Banca por Internet.</li>
                      <li>Selecciona <strong>Pagar servicio.</strong></li>
                      <li>Selecciona <strong>Agregar servicio a pagar</strong>.</li>
                      <li>En la sección <strong>Buscar servicio</strong> ingresa: Travel Payments - Orden de pago.</li>
                      <li>Donde dice <strong>id Cliente </strong>ingresa el <strong>código de pago</strong> enviado por correo electrónico.</li>
                      <li>Continúa con tu proceso de pago</li>
                    </ol>
                  </div>
                </div>
            </div>
            <div className="mb-10">
              <div>
                **Si se apersona a la ventanilla del banco tendrá que indicar que hará el pago a
                  la <strong>Recaudadora Travel Payments Ⓡ</strong>, deberá brindar el <strong>Código De Pago</strong> enviado por correo
                  electrónico.
              </div>
            </div>
        </div>

        <div className="" style={{marginTop: "40px"}}>
            <div className="mb-10 banks">
                <div className="wrapper"><a><div className="wrapper-img"><img src={bcp} alt="bcp"/></div></a></div>
            </div>
            <div className="mb-10 banks-summary">
                <div className="mr-30">
                  <div className="summary-bbva step-bbva">
                    <ul className="list">
                      <li>Podrás pagar desde el Aplicativo Móvil BCP, Telecrédito, Banca por Internet (Vía BCP), Ventanilla de Banco y Agente BCP.</li>
                      <li><strong>No hay costo por pago.</strong></li>
                    </ul>
                  </div>
                </div>
            </div>
            <div className="mb-10">
                <label className="content-title">Instrucciones de pago</label>
                <div className="mr-50">
                  <div>
                    <ol className="list">
                      <li>Ingresa a tu aplicación móvil BCP o Banca por Internet.</li>
                      <li>Selecciona la opción: <strong>Pagar servicios</strong>.</li>
                      <li>En <strong>buscar una empresa</strong> ingrese <strong>Travel Payments </strong>y selecciónelo.</li>
                      <li>En donde dice <strong>Ingresa el código de servicio</strong> ingresa el <strong>Código de Pago </strong>
                        enviado por correo electrónico.</li>
                      <li>Continúa con tu proceso de pago.</li>
                    </ol>
                  </div>
                </div>
            </div>
            <div className="mb-10">
              <div>
                **Si se apersona a la ventanilla del banco tendrá que indicar que hará el pago a
                  la <strong>Recaudadora Travel Payments Ⓡ</strong>, deberá brindar <strong>el Código De Pago</strong> enviado por correo
                  electrónico.
              </div>
            </div>
        </div>

        <div className="" style={{marginTop: "40px"}}>
            <div className="mb-10 banks">
                <div className="wrapper"><a><div className="wrapper-img"><img src={interbank} alt="interbank" style={{border: "1px solid green", borderRadius: "7%"}}/></div></a></div>
            </div>
            <div className="mb-10 banks-summary">
                <div className="mr-30">
                  <div className="summary-bbva step-bbva">
                    <ul className="list">
                      <li>Podrás pagar desde el Aplicativo Móvil Interbank y Banca por Internet Interbank.</li>
                      <li><strong>No hay costo por pago.</strong></li>
                    </ul>
                  </div>
                </div>
            </div>
            <div className="mb-10">
                <label className="content-title">Instrucciones de pago</label>
                <div className="mr-50">
                  <div>
                    <ol className="list">
                      <li>Ingresa a tu aplicación móvil o a tu Banca por Internet.</li>
                      <li>Ingresa a <strong>Operaciones </strong> y luego selecciona <strong>Servicios.</strong></li>
                      <li>Selecciona <strong>Instituciones</strong>.</li>
                      <li>Busca y selecciona <strong>TRAVEL PAYMENTS</strong>.</li>
                      <li>Selecciona <strong>"ORDEN DE PAGO"</strong>.</li>
                      <li>Donde dice <strong>CODIGO PAGO</strong> ingresa <strong>el Código De Pago</strong> enviado por correo
                        electrónico.</li>
                      <li>Continúa con tu proceso de pago.</li>
                    </ol>
                  </div>
                </div>
            </div>
        </div>

        <div className="" style={{marginTop: "40px"}}>
            <div className="mb-10 banks">
                <div className="wrapper"><a><div className="wrapper-img"><img src={scotiabank} alt="scotiabank"/></div></a></div>
            </div>
            <div className="mb-10 banks-summary">
                <div className="mr-30">
                  <div className="summary-scotiabank step-bbva">
                    <ul className="list">
                      <li>Podrás pagar desde el canal Web, App Móvil y Telebanking.</li>
                      <li><strong>No hay costo por pago.</strong></li>
                    </ul>
                  </div>
                </div>
            </div>
            <div className="mb-10">
                <label className="content-title">Instrucciones de pago</label>
                <div className="mr-50">
                  <div>
                    <ol className="list">
                      <li>Ingresa a tu aplicación móvil o a tu Banca por Internet.</li>
                      <li>En la sección <strong>Quiero</strong> debes seleccionar la opción <strong>Pagar.</strong></li>
                      <li>Selecciona la opción <strong>Servicios o instituciones.</strong></li>
                      <li>Digita <strong>Travel Payments</strong> y selecciónalo.</li>
                      <li>Elija la opción <strong>Orden de Pago</strong> y en donde dice <strong>ID Cliente </strong> ingresa <strong>el Código De
                        Pago</strong> enviado por correo electrónico.</li>
                      <li>Continúa con tu proceso de pago.</li>
                    </ol>
                  </div>
                </div>
            </div>
        </div>

      </div>
    )
  }
}

export default TravelPaymentFrame;