import React, { Component } from "react";
import bbva from "../../../assets/images/banks/bbva.png";
import bcp from "../../../assets/images/banks/bcp.png";
import interbank from "../../../assets/images/banks/interbank.png";
import scotiabank from "../../../assets/images/banks/scotiabank.png";
import "../../../assets/stylesheets/TravelPayment.css";

class TravelPaymentContent extends Component {
    constructor(props) {
        super(props);
    
        this.state = {
          isBBVA: true,
          isBCP: false,
          isInterbank: false,
          isScotiabank: false
        };
    
    }

    render() {
        return (
        <div className="payment-container">
            <div>
                <label className="txt-simp">Puedes realizar el pago con Débido o Crédito Visa, Mastercard y American Express en los siguientes Bancos:</label>
                <div className="banks-buttons">
                    <div className="mb-10 banks">
                        <div className="wrapper">
                            <a className={ this.state.isBBVA ? "selected": null} onClick={() => this.setState({isBBVA: true, isBCP: false, isInterbank: false, isScotiabank:false})}>
                                <div className="wrapper-img">
                                    <img src={bbva} alt="bbva"/>
                                </div>
                            </a>
                        </div>
                        <div className="wrapper">
                            <a className={ this.state.isBCP ? "selected": null} onClick={() => this.setState({isBBVA: false, isBCP: true, isInterbank: false, isScotiabank:false})}>
                                <div className="wrapper-img">
                                    <img src={bcp} alt="bcp"/>
                                </div>
                            </a>
                        </div>
                        <div className="wrapper">
                            <a className={ this.state.isInterbank ? "selected": null} onClick={() => this.setState({isBBVA: false, isBCP: false, isInterbank: true, isScotiabank:false})}>
                                <div className="wrapper-img">
                                    <img src={interbank} alt="interbank" style={{border: "1px solid green", borderRadius: "7%"}}/>
                                </div>
                            </a>
                        </div>
                        <div className="wrapper">
                            <a className={ this.state.isScotiabank ? "selected": null} onClick={() => this.setState({isBBVA: false, isBCP: false, isInterbank: false, isScotiabank:true})}>
                                <div className="wrapper-img">
                                    <img src={scotiabank} alt="scotiabank"/>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="mb-10 banks-summary">
                    <div className="mr-30">
                        { this.state.isBBVA ?
                            <div className="summary-bbva step-bbva">
                                <ul className="list">
                                <li>Podrás pagar desde tu Banca por Internet BBVA, Terminal Financiero, Saldo Express, Banca por teléfono y App móvil del Banco.</li>
                                <li><strong>No hay costo por pago.</strong></li>
                                </ul>
                            </div> : null
                        }
                        { this.state.isBCP ?
                            <div className="summary-bcp step-bcp">
                                <ul className="list">
                                    <li>Podrás pagar desde el Aplicativo Móvil BCP, Telecrédito, Banca por Internet (Vía BCP), Ventanilla de Banco y Agente BCP.</li>
                                    <li><strong>No hay costo por pago.</strong></li>
                                </ul>
                            </div> : null
                        }
                        { this.state.isInterbank ?
                            <div className="summary-ibk step-ibk">
                                <ul className="list">
                                    <li>Podrás pagar desde el Aplicativo Móvil Interbank y Banca por Internet Interbank.</li>
                                    <li><strong>No hay costo por pago.</strong></li>
                                </ul>
                            </div>: null
                        }
                        { this.state.isScotiabank ?
                            <div className="summary-ibk step-ibk">
                                <ul className="list">
                                    <li>Podrás pagar desde el canal Web, App Móvil y Telebanking.</li>
                                    <li><strong>No hay costo por pago.</strong></li>
                                </ul>
                            </div>: null
                        }
                    </div>
                </div>
                <div className="mb-10 banks-steps">
                    <label className="content-title">Instrucciones de pago</label>
                    <div className="mr-50">
                        { this.state.isBBVA ?
                            <div className="steps-bbva step-bbva">
                                <ol className="list">
                                    <li>Ingresa a tu aplicativo móvil BBVA o Banca por Internet.</li>
                                    <li>Selecciona <strong>Pagar servicio.</strong></li>
                                    <li>Selecciona <strong>Agregar servicio a pagar</strong>.</li>
                                    <li>En la sección <strong>Buscar servicio</strong> ingresa: Travel Payments - Orden de pago.</li>
                                    <li>Donde dice <strong>id Cliente </strong>ingresa el <strong>código de pago</strong> enviado por correo electrónico.</li>
                                    <li>Continúa con tu proceso de pago</li>
                                </ol>
                            </div> : null
                        }
                        { this.state.isBCP ?
                            <div className="steps-bcp step-bcp">
                                <ol className="list">
                                    <li>Ingresa a tu aplicación móvil BCP o Banca por Internet.</li>
                                    <li>Selecciona la opción: <strong>Pagar servicios</strong>.</li>
                                    <li>En <strong>buscar una empresa</strong> ingrese <strong>Travel Payments </strong>y selecciónelo.</li>
                                    <li>En donde dice <strong>Ingresa el código de servicio</strong> ingresa el <strong>Código de Pago </strong> 
                                        enviado por correo electrónico.</li>
                                    <li>Continúa con tu proceso de pago.</li>
                                </ol>
                            </div> : null
                        }
                        { this.state.isInterbank ?
                            <div className="steps-ibk step-ibk">
                                <ol className="list">
                                    <li>Ingresa a tu aplicación móvil o a tu Banca por Internet.</li>
                                    <li>Ingresa a <strong>Operaciones </strong> y luego selecciona <strong>Servicios.</strong></li>
                                    <li>Selecciona <strong>Instituciones</strong>.</li>
                                    <li>Busca y selecciona <strong>TRAVEL PAYMENTS</strong>.</li>
                                    <li>Selecciona <strong>"ORDEN DE PAGO"</strong>.</li>
                                    <li>Donde dice <strong>CODIGO PAGO</strong> ingresa <strong>el Código De Pago</strong> enviado por correo 
                                        electrónico.</li>
                                    <li>Continúa con tu proceso de pago.</li>
                                </ol>
                            </div> : null
                        }
                        { this.state.isScotiabank ?
                            <div className="steps-ibk step-ibk">
                                <ol className="list">
                                    <li>Ingresa a tu aplicación móvil o a tu Banca por Internet.</li>
                                    <li>En la sección <strong>Quiero</strong> debes seleccionar la opción <strong>Pagar.</strong></li>
                                    <li>Selecciona la opción <strong>Servicios o instituciones.</strong></li>
                                    <li>Digita <strong>Travel Payments</strong> y selecciónalo.</li>
                                    <li>Elija la opción <strong>Orden de Pago</strong> y en donde dice <strong>ID Cliente </strong> ingresa <strong>el Código De
                                        Pago</strong> enviado por correo electrónico.</li>
                                    <li>Continúa con tu proceso de pago.</li>
                                </ol>
                            </div> : null
                        }
                    </div>
                </div>
                <div className="mb-10 banks-steps">
                        {this.state.isBBVA ?
                            <div className="steps-bbva step-bbva">
                                **Si se apersona a la ventanilla del banco tendrá que indicar que hará el pago a
                                la <strong>Recaudadora Travel Payments Ⓡ</strong>, deberá brindar el <strong>Código De Pago</strong> enviado por correo
                                electrónico.                       </div> : null
                        }
                        {this.state.isBCP ?
                            <div className="steps-bcp step-bcp">
                                **Si se apersona a la ventanilla del banco tendrá que indicar que hará el pago a
                                la <strong>Recaudadora Travel Payments Ⓡ</strong>, deberá brindar <strong>el Código De Pago</strong> enviado por correo
                                electrónico.
                            </div> : null
                        }
                </div>
            </div>
        </div>
        )
    }
}

export default TravelPaymentContent;