import React, { Component } from "react";

class InsuranceRules extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isChecked: false
        }
    }

    render() {
        return (
            <div className="row mt-3 mb-3">
                <div className="col">
                    <div className="checkbox">
                        <label className="control control--checkbox" style={{ marginRight: "10px" }}>
                        </label>
                        <input type="hidden" value={this.state.isChecked} id="ir_f" />
                        <div className="d-flex">
                            <input
                                type="checkbox"
                                className="cursor-pointer"
                                defaultChecked={this.state.isChecked}
                                onChange={e => {
                                    const checked = e.target.checked;
                                    console.log('checkbox presionado ...');
                                    this.setState({
                                        isChecked: checked
                                    }, () => {
                                        //
                                    })
                                }}
                            />
                            <p className="ml-3">
                                Leo, entiendo y acepto las
                                <a
                                    href="#flightRulesModal"
                                    style={{ color: "rgb(0, 80, 171)", fontWeight: "400" }}
                                    className="ml-1"
                                    onClick={e => {
                                        e.preventDefault();
                                        this.setState({ activeModal: true });
                                        window.Swal.fire({
                                            title: 'Condiciones de compra',
                                            text: 'Estas son las condiciones de compra',
                                            icon: 'success',
                                            showCancelButton: false,
                                            confirmButtonColor: '#3085d6',
                                            cancelButtonColor: '#d33',
                                            confirmButtonText: 'OK'
                                        });
                                    }}>
                                    condiciones de compra</a>, <a style={{ color: "rgb(0, 80, 171)", fontWeight: "400" }} href="https://costamar.com.pe/politicas-privacidad" target="_blank">políticas de privacidad</a> y <a style={{ color: "rgb(0, 80, 171)", fontWeight: "400" }} href="https://costamar.com.pe/terminos-condiciones" target="_blank">términos y condiciones de uso</a>
                                .
                            </p>
                            <div className="control__indicator"></div>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}

export default InsuranceRules;