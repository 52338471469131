import React, { Component } from 'react';
//import logoColor from '../../images/PASAJESBARATOS.png';
import "../../assets/stylesheets/Navbar.css";

class Navbar extends Component {
    render() {
        return(
            <header>
                <nav className="navbar pt-3 pb-3 navbar-main navbar-white"
                    style={{zIndex: "0"}}>
                    <div className="container navbar-mobile navbar-white">
                        <div className="row w-full w-full-mobile">
                            <div className="col">
                                <div className="navbar-brand">
                                    <div className="navbar-item">
                                            <a href="/">
                                                <img
                                                    className="logo"
                                                    src="https://costamar.com.pe/static/media/logo-color.a474ea8d.png"
                                                    alt="src logo"
                                                    >
                                                </img>
                                            </a>
                                    </div>


                                </div>  

                            </div>
                        </div>

                        <div className="d-flex bd-highlight mb-3 mt-3 w-full-mobile">
                            <div className="p-2 bd-highlight" style={{color: "black"}}>info@costamar.com</div>
                        </div>
                    </div>
                </nav>
            </header>
        )
    }
}

export default Navbar;