import React, { Component } from 'react';
import AcuotazFrame from './payment/AxuotazFrame';
import NiubizFrame from './payment/NiubizFrame';
import PaymentFrame from './payment/PaymentFrame';
import TravelPaymentFrame from './payment/TravelPaymentFrame';

class PaymentDetails extends Component {

    renderPaymentFrameByPaymentProvider(paymentProvider) {
        switch (paymentProvider) {
            case "125":
                return(<AcuotazFrame />)
            case "128":
                return(<TravelPaymentFrame booking={this.props.data.registerRS} data={this.props.data}/>)
            case "130":
                return(<NiubizFrame />)    
        
            default:
                return(<PaymentFrame paymentProvider={paymentProvider} data={this.props.data} />);
        }
        
    }

    render() {
        const { data } = this.props;
        const paymentProvider = data.registerRS.providerCode;

        return (
            <div className='row PaymentDetails'>
                <div className='col-12 rounded-sm'>
                    {this.renderPaymentFrameByPaymentProvider(paymentProvider)}
                </div>
            </div>
        )
    }
}

export default PaymentDetails;