import axios from "axios";
import { LOAD_ENGINE } from "./types";

import {
    BASE_URL
  } from "../config/keys";

const requestConfig = {
    withCredentials: true
}

export const loadEngine = ({
    engineId
}) => async dispatch => {
    console.log('loadEngine -> ' , engineId);
    try {
        const res = await axios.get(`https://insurance.zdev.tech/api/engines/${engineId}`, requestConfig);
        console.log('luego de consumir wsde loadEngine -< ', res.data);
        dispatch({
            type: LOAD_ENGINE,
            payload: res.data
        })
    } catch (error) {
        console.error('error in loadEngine actions -> ', error.message);
    }
    
    /*dispatch({
        type: LOAD_ENGINE,
        payload: res.data
    });*/
}
