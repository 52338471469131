export const CASH = '103';
export const PAGOEFECTIVO = '102';
export const SAFETYPAY = '1002';
export const PAYME = '1003';
export const CYBERSOURCE = '1004';
export const TARJETAOH = '122';
export const SCOTIABANK = '123';
export const CUOTEALO = '137';
export const RIPLEY = '121';
export const INTERBANK = '104';
export const ACUOTAZ = '1010';
export const PEYMI = '126';
export const NIUBIZ_DISENGAGED_ID = '1012';
export const NIUBIZ_BUTTON_ID = '1013';
export const TRAVEL_PAYMENTS = '128';
export const OPENPAY_ID = '135';
export const POWERPAY_ID = '136';
export const CREDIT_CARD = "CARD"; 
export const CUOTEALO_BCP = "124";
export const PAYPAL = "139";