import React, { Component } from "react";
import { connect } from "react-redux";

import { fillPaymentCreditCardForm } from "../../../actions/bookFormActions";

import logoCostamar from "../../../assets/images/payment/niubiz/logo.png";
import logos from "../../../assets/images/payment/niubiz/logos.png";
import cardValidator from "card-validator";
import validator from "../../../utils/validator";

class CreditCardForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            cardNumber: "",
            expirationDate: "",
            securityCode: "",
            firstName: "",
            lastName: "",
            email: "",
            telephone: "",
            countryCode: "US",
            city: "",
            state: "",
            zipCode: "",
            address: "",
            validates: {},
            isValid: false,
            isAmericanExpress: false
        }
    }

    componentWillReceiveProps(nextProps) {
        this.validate();
    }

    validate() {
        let isValid = false;
        let validates = {};

        for (let field in this.state) {
            const { message } = this.validateField(field);
            if (typeof message !== "undefined") validates[field] = { message };
          }
      
          isValid = Object.keys(validates).length === 0 ? true : false;
          this.setState({ validates: validates, isValid: isValid });
    }

    validateField(field) {
        console.log('$$$$$$$ begin field in validateField() -> $$$$$', field);
        let validates = {};
        let validation;
    
        switch (field) {
          case "cardNumber":
            validation = validator.isPresent(this.state.cardNumber);
            if (!validation.isValid) {
              validates = validation.error;
              break;
            }
    
            validation = validator.isCreditCard(this.state.cardNumber);
            if (!validation.isValid) {
              validates = validation.error;
              break;
            }
            break;
          case "expirationDate":
            validation = validator.isPresent(this.state.expirationDate);
            if (!validation.isValid) {
              validates = validation.error;
              break;
            }
    
            validation = validator.isExpirationDate(this.state.expirationDate);
            if (!validation.isValid) {
              validates = validation.error;
              break;
            }
    
            break;
          case "securityCode":
            validation = validator.isPresent(this.state.securityCode);
            if (!validation.isValid) {
              validates = validation.error;
              break;
            }
    
            validation = validator.isNumber(this.state.securityCode);
            if (!validation.isValid) {
              validates = validation.error;
              break;
            }
    
            validation = validator.minMaxLength(this.state.securityCode, 3, 4);
            if (!validation.isValid) {
              validates = validation.error;
              break;
            }
    
            break;
          case "firstName":
            validation = validator.isPresent(this.state.firstName);
            if (!validation.isValid) {
              validates = validation.error;
              break;
            }
    
            break;
          case "lastName":
            validation = validator.isPresent(this.state.lastName);
            if (!validation.isValid) {
              validates = validation.error;
              break;
            }
    
            break;
          case "email":
            validation = validator.isPresent(this.state.email);
            if (!validation.isValid) {
              validates = validation.error;
              break;
            }
    
            validation = validator.isEmail(this.state.email);
            if (!validation.isValid) {
              validates = validation.error;
              break;
            }
            break;
          default:
          // nothing
        }
        console.log('validates a retornar !! ', validates);
        console.log('$$$$$$$$ end field in validateField() -> $$$$', field);

        return validates;
      }

    render() {
        console.log('state in render of CreditCardForm -> ',this.state);
        return (
            <div>
                <div className="row justify-content-md-center">
                    <div className="col-md-6 col-12 mt-5 mb-5 pt-5 pb-5">
                        <div className="card">

                            <div className='payment-logo'>
                                <img src={logoCostamar} className="logo" alt="logoCostamar" />
                            </div>

                            <p className='text-logo pt-3'>
                                <label
                                    style={{ textAlign: "center" }}>
                                    <strong>Recuerda</strong> activar las
                                    <strong> compras por internet</strong>
                                    <br></br>con tu banco
                                </label>
                            </p>

                            <div className="card-body">

                                <div className='card space icon-relative mb-0'>
                                    <input type="text"
                                        className="inputNiubiz" placeholder="Numero de tarjeta"
                                        name='cardNumber'
                                        value={this.state.cardNumber}
                                        onChange={e => {
                                            this.setState({ cardNumber: e.target.value }
                                            );
                                        }}
                                        onBlur={e => {
                                            let val = e.target.value;
                                            let cardNumber = val.replace(/\s/g, "");
                                            /*let card = cardValidator.number(cardNumber);*/
                                            console.log('cardNumber antes del validator -> ', cardNumber);
                                            const result = cardValidator.number(cardNumber);
                                            console.log('result del validator -> ', result);
                                            let card = {
                                                isValid: true,
                                                card: {
                                                    type: "VI"
                                                }
                                            }
                                            if (card.isValid) {
                                                let type = card.card.type;
                                                this.props.fillPaymentCreditCardForm({
                                                    cardNumber: cardNumber,
                                                    cardType: type
                                                });
                                            }

                                        }}
                                    />
                                    <i class="fas fa-credit-card"></i>
                                </div>

                                <br />

                                <div className="card-grp space">
                                    <div className="card-item icon-relative">
                                        <input type="text"
                                            className="inputNiubiz" placeholder="04/25"
                                            name='cardDate'
                                            value={this.state.expirationDate}
                                            onChange={e => {
                                                let value = e.target.value;
                                                const addCharacter = { char: "/", offset: 2 }
                                                const rejectInput = { rule: "max", param: 5 }

                                                if (
                                                    value.length === addCharacter.offset &&
                                                    value.indexOf(addCharacter.char.toString()) === -1
                                                ) {
                                                    if (
                                                        this.state.expirationDate.length !== value.length + 1 &&
                                                        this.state.expirationDate.indexOf(addCharacter.char.toString()) === -1
                                                    ) {
                                                        value += addCharacter.char.toString();
                                                    }
                                                }

                                                if (value.length > rejectInput.param) {
                                                    return false;
                                                }

                                                this.setState({ expirationDate: value }
                                                );
                                            }}
                                            onBlur={e =>
                                                this.props.fillPaymentCreditCardForm({ expirationDate: e.target.value })
                                            }
                                        />
                                        <i className="far fa-calendar-alt"></i>
                                    </div>
                                    <div className="card-item icon-relative d-flex">
                                        <input type="text"
                                            className="inputNiubiz" placeholder="CVV"
                                            name='textCVV'
                                            value={this.state.securityCode}
                                            onChange={e => {
                                                let value = e.target.value;
                                                /*const rejectInput = { rule: "max", param: ancillariesUtils.getMaxLengthForCVVInCreditCard(this.state.isAmericanExpress) }
                                                if (value.length > rejectInput.param) {
                                                    return false;
                                                }*/
                                                if (value.length > 4) {
                                                    return false;
                                                }
                                                this.setState({ securityCode: value }
                                                );
                                            }}
                                            onBlur={e =>
                                                this.props.fillPaymentCreditCardForm({ securityCode: e.target.value })
                                            }
                                        />
                                        <div><i className="fas fa-lock"></i></div>
                                    </div>
                                </div>

                                <div className="card-grp space mt-4">
                                    <div className="card-item icon-relative">
                                        <input type="text"
                                            className="inputNiubiz" placeholder={"Nombre"}
                                            name='nombre'
                                            value={this.state.firstName}
                                            onChange={e => {
                                                this.setState({ firstName: e.target.value }
                                                );
                                            }}
                                            onBlur={e => {
                                                const val = e.target.value;
                                                this.props.fillPaymentCreditCardForm({ firstName: val })
                                            }
                                            }
                                        />
                                        <i className="fas fa-user"></i>
                                    </div>
                                    <div className="card-item icon-relative">
                                        <input type="text" className="inputNiubiz"
                                            placeholder={"Apellido"}
                                            name='apellido'
                                            value={this.state.lastName}
                                            onChange={e => {
                                                this.setState({ lastName: e.target.value }
                                                );
                                            }}
                                            onBlur={e =>
                                                this.props.fillPaymentCreditCardForm({ lastName: e.target.value })
                                            }
                                        />
                                        <i className="fas fa-user"></i>
                                    </div>

                                </div>

                                <div className="card space icon-relative">
                                    <input type="text" className="inputNiubiz"
                                        placeholder={"Correo"}
                                        name='email'
                                        value={this.state.email}
                                        onChange={e => {
                                            this.setState({ email: e.target.value }
                                            );
                                        }}
                                        onBlur={e =>
                                            this.props.fillPaymentCreditCardForm({ email: e.target.value })
                                        }
                                    />
                                    <i className="fas fa-user"></i>
                                </div>

                                <div className="row mt-4">
                                    <div className="col-md-12" id="cuotas"
                                        style={{ display: "none" }}></div>
                                </div>

                                <div className='row'>
                                    <div className='col-12'>
                                        <img src={logos} className="logos w-100" alt="" />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <input type="hidden" value={this.state.isValid} id="cd_f" />
            </div>
        )
    }
}

function mapStateToProps({ bookFormReducer }) {
    return {
        bookForm: bookFormReducer
    };
}

export default connect(mapStateToProps, { fillPaymentCreditCardForm })(CreditCardForm);
