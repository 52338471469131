import React, { Component } from 'react';
import '../../assets/stylesheets/LoadingProgress.css';

class LoadingProgress extends Component {

    getClassByActiveStep(activeStep) {
        switch (activeStep) {
            case 0:
                return "progress-value-0"

            case 1:

                return "progress-value-1";

            case 2:

                return "progress-value-2";

            default:
                break;
        }
    }

    getMainClassByActiveStep(activeStep) {
        switch (activeStep) {
            case 0:
                return "progress-0"

            case 1:

                return "progress-1";

            case 2:

                return "progress-2";

            default:
                break;
        }
    }

    render() {
        const classNameByStep = this.getClassByActiveStep(this.props.activeStep);
        const mainClassByStep = this.getMainClassByActiveStep(this.props.activeStep);
        return (
            <div className={`progress ${mainClassByStep}`}>
                <div className={`${classNameByStep} progress-value`}></div>
            </div>
        );
    }
}

export default LoadingProgress;