import React, { Component } from "react";
import { connect } from 'react-redux';
import '../../assets/stylesheets/Insurances.css';
import uuid from "react-uuid";
import { selectInsurance } from '../../actions/insurancesActions';
import InsuranceModal from "../checkout/InsuranceModal";
import { AGES_PARAM_INDEX } from "../../constants/searchParamConstants";
import universalLogo from '../../assets/images/banners/logo-ua.svg';
import checkIcon from '../../assets/images/icons/check.svg';
import engineUtils from "../../utils/enginesUtils";
import { Popover, OverlayTrigger, Tooltip } from "react-bootstrap";

class Insurance extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedModal : {
                id: null,
                openModal: false
            },
            isLoading: false,
            isAnual: false,
            quantityTypeAnual: this.getQuantityTypeAnual(),
            quantityTypeDaily: this.getQuantityTypeDaily()
        }
    }

    getQuantityTypeAnual() {
        return this.props.recommendations.filter(r => r.typeOfSell === "ANUAL").length;
    }

    getQuantityTypeDaily() {
        return this.props.recommendations.filter(r => r.typeOfSell === "DIAS").length;
    }

    componentDidMount() {
        if(this.props.insurance.typeOfSell === "ANUAL") {
            this.setState({isAnual: true});
        }
    }

    onSubmit = (isLoadingForRecommendationButton) => {

        const pathSearch = window.location.origin + window.location.pathname;

        const data = {
            insurance: this.props.insurance,
            searchParams: this.props.params,
            sessionID: uuid(),
            pathSearch: pathSearch,
            engine: this.props.engine,
            isAgency: this.props.isAgency
        }
        let isLoading = false;
        if(isLoadingForRecommendationButton) {
            isLoading = true;
        }
        this.setState({
            isLoading: isLoading
        }, () => this.props.selectInsurance(data)
    );
    }

    openModal = () => {
        const productId = this.props.insurance.product.idProduct;
        const selectedModal = {
            id: productId,
            openModal: true
        }
        this.setState({ selectedModal : selectedModal });
    }

    closeModal = () => {
        const selectedModal = {
            id: null,
            openModal: false
        }
        this.setState({ selectedModal: selectedModal })
    }

    renderBannerAnual = (bannerStyles) => {
        if (this.state.isAnual && this.props.index === 0) {
            return (<div className={`pt-2 pb-2 pl-3 pr-3 mb-2 row rounded-sm text-white banner ${bannerStyles}`}>
                 <div id="" className="d-flex">
                    <p className="mb-0 ml-3">Planes Anuales (Varios viajes en un año) </p><AnualFamilyTooltip id="anual" />
                 </div>
                 <div><img src={universalLogo} /></div>
            </div>)
        } else {
            return null;
        }
    }

    renderBannerDaily = (bannerStyles) => {
        if(!this.state.isAnual) {
            if(this.state.quantityTypeAnual === 0 && this.props.index === 0) {
                return (<div className={`pt-2 pb-2 pl-3 pr-3 mb-2 row rounded-sm text-white banner ${bannerStyles}`}
                >
                    <div className="d-flex" id="">
                        <p>Planes por días</p>
                        <div className="ml-3 mb-0" id="daily"><DailyFamilyTooltip id="daily" /></div>
                    </div>
                    <div><img src={universalLogo} /></div>
                </div>)
            } else if(this.state.quantityTypeAnual === 1 && this.props.index === 1) {
                return (<div className={`pt-2 pb-2 pl-3 pr-3 mb-2 row rounded-sm text-white banner ${bannerStyles}`}
                >
                    <div className="d-flex" id="">
                        <p className="mb-0">Planes por días</p>
                        <div className="ml-3 mb-0" id="daily"><DailyFamilyTooltip id="daily" /></div>
                    </div>
                    <div><img src={universalLogo} /></div>
                </div>)
            } else if(this.state.quantityTypeAnual === 2 && this.props.index === 2) {
                return (<div className={`pt-2 pb-2 pl-3 pr-3 mb-2 row rounded-sm text-white banner ${bannerStyles}`}
                >
                    <div className="d-flex" id="">
                        <p className="mb-0">Planes por días</p>
                        <div className="ml-3 mb-0" id="daily"><DailyFamilyTooltip id="daily" /></div>
                    </div>
                    <div><img src={universalLogo} /></div>
                </div>)
            }
        } else {
            return null;
        }
    }

    render() {
        const { insurance, params, recommendations } = this.props;
        const bannerStyles = this.state.isAnual ? "banner-anual" : "banner-daily";
        const buttonStyles = this.state.isAnual ? "btn-bg-anual" : "btn-bg-daily";         
        return (
            <div>
                {this.renderBannerAnual(bannerStyles)}
                {this.renderBannerDaily(bannerStyles)}

               
                <div className={`Insurance_item ${'productTypeStyle'} rounded-sm row mb-3`}
                    id={insurance.product.idProduct}>
                    <div className="col-3 mt-auto mb-auto text-center">
                        <div className="title-item">{insurance.product.product}</div>
                        <div className="title-item">{insurance.product.idProduct}</div>
                    </div>
                    <div className="col-6 mt-auto mb-auto InsurancesDetailsInfo">
                        <InsurancesDetailsInfo
                            product={insurance.product}
                            id={insurance.product.idProduct}
                            openModal={this.openModal}
                            state={this.state}
                        />
                    </div>
                    <div className="col-3">
                        <InsurancesPricingInfo
                            product={insurance.product}
                            onClick={this.onSubmit}
                            value={insurance.product.idProduct}
                            params={params}
                            state={this.state}
                            isAgency={this.props.isAgency}
                            buttonStyles={buttonStyles}
                            insurance={insurance}
                        />
                    </div>
                    <div className="mt-3">
                        <InsuranceModal
                            product={insurance.product}
                            selectedModal={this.state.selectedModal}
                            closeModal={this.closeModal}
                            onSubmit={this.onSubmit}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

const InsurancesDetailsInfo = ({ product, id, openModal, state }) => {
    return (
        <div className="mb-3 mt-3">
            {product.coverageItems.slice(0, 5).map((item, index) => {
                return (
                    <div key={index}>
                        <p className="content-item">
                            <img src={checkIcon} />
                            {item.description.substring(4, item.description.length)} |
                            {
                                item.amount && item.unidad && item.valor === null ? ` ${item.unidad} ${engineUtils.formatThousandAmount(item.amount)}`
                                    : item.amount === null && item.unidad === null && item.valor ? ` ${item.valor}` : null
                            }
                        </p>
                    </div>)
            })
            }
            <div>
                <i class='fas fa-eye icon-to-modal'></i>
                <a className="btn button-to-modal" 
                    onClick={() => {
                        openModal(id);
                    }}
                >
                    Ver más detalles de la cobertura</a>
            </div>
        </div>
    )
}

const InsurancesPricingInfo = ({ product, onClick, value, params, state, isAgency, buttonStyles, insurance }) => {
    const passengersQuantity = params && params[AGES_PARAM_INDEX] && params[AGES_PARAM_INDEX] !== "" ? params[AGES_PARAM_INDEX].split(",").length : 0;
    let feesToFinalPrice = [];
    let totalFees = 0;
    if (product.fareProduct.fee) {
        //feesToFinalPrice = product.fareProduct.fees.map(f => f.amount);
        //totalFees = feesToFinalPrice.reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
        totalFees = totalFees + parseFloat(parseFloat(product.fareProduct.fee.feeCostamarApply).toFixed(2));
    
    }
    return (
        <div className="mb-3 mt-3 InsurancesPricingInfo-container">
            <div className="main-fare mb-3">
                <span className="fare-price-text">Precio por pasajero</span>
                <span className="fare-price-amount">
                    <span className="price-currency"></span>USD <span className="price-amount">
                        {parseFloat(product.fareProduct.baseFareByPax).toFixed(2)}</span>
                </span>
            </div>
            <div className="details-fare">
                <div className="item-fare">
                    <span className="item-fare-description">N° pasajeros</span>
                    <span className="item-fare-price">{passengersQuantity}</span>
                </div>
                <div className="item-fare">
                    <span className="item-fare-description">Impuestos, cargos y tasas</span>
                    <span className="item-fare-price">USD {parseFloat(totalFees).toFixed(2)}</span>
                </div>
                {product.fareProduct && product.fareProduct.fee && isAgency ?
                    <div className="mt-3">
                        <div className="item-fare">
                            <span className="item-fare-description">Fee costamar apply (USD)</span>
                            <span className="item-fare-price">{parseFloat(product.fareProduct.fee.feeCostamarApply).toFixed(2)}</span>
                        </div>
                        <div className="item-fare">
                            <span className="item-fare-description">Comisión agencia apply (USD)</span>
                            <span className="item-fare-price">{parseFloat(product.fareProduct.fee.comAgencyApply).toFixed(2)}</span>
                        </div>
                        <div className="item-fare mb-3">
                            <span className="item-fare-description">Comisión CTM apply (USD)</span>
                            <span className="item-fare-price">{parseFloat(product.fareProduct.fee.comCTMApply).toFixed(2)}</span>
                        </div>
                    </div> 
                : null}                          
                <div className="details-fare-total">
                    <span className="total-price-description">Precio final</span>
                    <span className="total-price-amount">
                        <span className="total-currency">USD </span>
                        <span className="total-amount">{parseFloat(product.fareProduct.totalToPaid).toFixed(2)}</span>
                    </span>
                </div>
            </div>
            <div className="button-fare mt-3">
                <button
                    className={`btn InsuranceButton ${buttonStyles}`}
                    value={value}
                    disabled={!insurance.sellable}
                    onClick={onClick}>{state.isLoading ? 
                        (<div className={`spinner-border ms-auto spinner-border-insurance`} role="status" aria-hidden="true"></div>)
                        : !insurance.sellable ? (<div><IssuePriceTooltip insurance={insurance} />Comprar </div>)
                        :   "Comprar"
                        }    
                </button>
            </div>
        </div>
    )
}

const tooltip = (id, message) => {
    return(
        <Tooltip
            id={`tooltip-description-${id}`}
            key={id}
      ><p>{message}</p>
      </Tooltip>
    )
}

const IssuePriceTooltip = (props) => {
    const { insurance } = props;
    const id = insurance.product.idProduct;
    const message =  `No se puede comprar este producto porque el monto mínimo es USD ${insurance.product.fareProduct.issuePrice}`;
    return (
        <OverlayTrigger placement="auto"
            overlay={tooltip(id, message)}
        >
            <div style={{ width: "3px", height: "3px", color: "black" }}>
                <i className="fa fa-exclamation-triangle" aria-hidden="true"></i>
            </div>
        </OverlayTrigger>
    );
}

const AnualFamilyTooltip = (props) => {
    const id = props.id;
    const message =  `¿Viajas seguido? Esta cobertura te protege en todos los viajes que hagas durante un año. 
                        Pagas una vez ¡y listo! Viaja tranquilo /a cuando quieras.
                        Válido para viajes de hasta 30 días cada uno`;
    return (
        <OverlayTrigger 
            placement="bottom"
            trigger={"click"}
            overlay={tooltip(id, message)}
        >
            <div style={{ width: "5px", height: "5px", color: "black" }}>
                <i className="fa fa-question-circle ml-2" aria-hidden="true" style={{cursor: "pointer"}}></i>
            </div>
        </OverlayTrigger>
    );
}

const DailyFamilyTooltip = (props) => {
    const id = props.id;
    const message =  `Nuestros planes por días sirven para viajes específicos. Con esta cobertura
                        viajas protegido/a la cantidad de días que dure tu viaje`;
    return (
        <OverlayTrigger 
            placement="bottom"
            trigger={"click"}
            overlay={tooltip(id, message)}
        >
            <div style={{ width: "5px", height: "5px", color: "black" }}>
                <i className="fa fa-question-circle ml-2" aria-hidden="true" style={{cursor: "pointer"}}></i>
            </div>
        </OverlayTrigger>
    );
}

const mapStateToProps = ({ insurancesReducer }) => {
    return {
        
    }
}

export default connect(mapStateToProps, { selectInsurance })(Insurance);