import React, { Component } from "react";

import { fillCommissionAgency } from '../../actions/bookFormActions';
import { connect } from "react-redux";

class PriceInformation extends Component {

    render() {
        console.log('render of PriceInformation -> ', this.props);
        const total = parseFloat(this.props.insurance.product.fareProduct.totalToPaid) + parseFloat(this.props.bookFormReducer.commissionAgency); 
        let totalFees = 0;
        if (this.props.insurance.product.fareProduct.fee) {
            //feesToFinalPrice = product.fareProduct.fees.map(f => f.amount);
            //totalFees = feesToFinalPrice.reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
            totalFees = totalFees + parseFloat(parseFloat(this.props.insurance.product.fareProduct.fee.feeCostamarApply).toFixed(2));
    
        }
        
        return (
            <div className="card mt-5">
                <div className="card-body">
                    <div className="mb-0 mt-0 InsurancesPricingInfo-container details-fare">
                        <div className="details-fare">
                            <div className="item-fare-price-information mb-3" style={{fontSize: "1.25rem", fontWeight: "500"}}>
                                <span className="item-fare-description fare-price-text">Tarifa por pasajero</span>
                                <span className="item-fare-price">USD {parseFloat(this.props.insurance.product.fareProduct.baseFareByPax).toFixed(2)}</span>
                            </div>
                            <div className="item-fare-price-information">
                                <span className="item-fare-description" style={{fontWeight: "600"}}>Pasajeros ( {this.props.insurance.passengersQuantity} )</span>
                                <span className="item-fare-price">USD {parseFloat(this.props.insurance.product.fareProduct.baseFare).toFixed(2)}</span>
                            </div>
                            <div className="item-fare-price-information">
                                <span className="item-fare-description" style={{fontWeight: "600"}}>Impuestos, cargos y tasas</span>
                                <span className="item-fare-price">USD {parseFloat(totalFees).toFixed(2)}</span>
                            </div>
                            <div className="item-fare-price-information">
                                <span className="item-fare-description" style={{fontWeight: "600"}}>Cargos adicionales</span>
                                <span className="item-fare-price">USD {parseFloat(this.props.bookFormReducer.commissionAgency).toFixed(2)}</span>
                            </div>
                            <div className="item-fare-price-information mt-3 border-top mb-0" style={{fontSize: "1.5rem", fontWeight: "500"}}>
                                <span className="item-fare-description">Total a pagar</span>
                                <span className="item-fare-price">USD {parseFloat(total).toFixed(2)}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = ({ bookFormReducer }) => {
    return {
        bookFormReducer
    }
}

export default connect(mapStateToProps, { fillCommissionAgency })(PriceInformation);