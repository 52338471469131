import React,{ Component } from 'react';
import engineUtils from "../../utils/enginesUtils";

class InsuranceInformation extends Component {
   
    render() {
        const origin = this.props.searchParams[0];
        const destination = this.props.searchParams[1];
        const startDate = this.props.searchParams[2];
        const endDate = this.props.searchParams[3];
        const passengers = this.props.searchParams[4].split(",")
        const daysOfCoverage = engineUtils.calculateElapsedDays(startDate, endDate);
        return (
            <div className='row p-3 pl-5' 
                style={{ background: "#033379", color: "white",
                            fontSize: "14px"}}>
                <div className='col-12 col-md-5'>
                    Producto elegido: <p style={{fontSize: "26px", fontWeight: "bold"}}>{this.props.insurance.product.product}</p>
                </div>
                <div className='col-6 col-md-5'>
                    <p className='mb-0'>Inicio: {startDate}</p>
                    <p className='mb-0'>Días cobertura: {daysOfCoverage}</p>
                    <p className='mb-0'>Origen: {origin}</p>
                    <p className='mb-0'>Destino: {destination}</p>
                    <p className='mb-0'>Total: USD {this.props.insurance.product.fareProduct.totalFare}</p>
                </div>
                <div className='col-6 col-md-2'>
                    <p>Fin: {endDate} </p>
                    <p>Pasajeros: {passengers.length}</p>
                </div>
            </div>
        )
    }
}

export default InsuranceInformation;