//import React from 'react';
import * as React from "react";

import {
    BrowserRouter,
    Route
} from "react-router-dom";
import Reservation from "../components/checkout/Reservation";
import BookingResult from "../components/booking/BookingResult";
import LoadingProgress from "../components/commons/LoadingProgress";
import InsurancesRecommendations from "../components/results/InsurancesRecommendations";

import Home from '../Home';

import "../assets/stylesheets/Application.css";

const AppRoutes = () => {
    return (
        <div>
            <BrowserRouter basename="">
                <div className="main">
                    <Route exact path="/"
                        component={Home}
                    />
                    <Route exact path="/:origin/:destination/:destinationIndex/:startDate/:endDate/:age"
                        component={InsurancesRecommendations}
                    />
                    <Route path="/insurance-selected/:startDate/:endDate/:id"
                        component={Reservation}
                    />
                    <Route path="/checkout/:id"
                        component={Reservation}
                    />
                    <Route path="/booking-result/:transactionId"
                        component={BookingResult}
                    />
                    <Route path="/loading-progress"
                        component={LoadingProgress}
                    />
                </div>
            </BrowserRouter>
        </div>
    )
}

export default AppRoutes;