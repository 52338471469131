import React, { Component } from "react";
import { CREDIT_CARD, CASH, TRAVEL_PAYMENTS } from "../../constants/paymentOptionsIDConstants";
import CreditCardForm from "../checkout/payment/CreditCardForm";
import PaymentCashContent from "../checkout/payment/PaymentCashContent";
import TravelPaymentContent from "./payment/TravelPaymentContent";

class PaymentContent extends Component {
    constructor(props) {
        super(props);
    }



    render() {

        return (
            <div className="bg-white border rounded-sm p-3">
                {this.props.optionSelected === CREDIT_CARD ? 
                    <CreditCardForm
                        onClick={this.props.onClick} 
                        onChangeAmexCreditCardCheckbox={this.props.onChangeAmexCreditCardCheckbox}
                    /> : null}
                {this.props.optionSelected === CASH ? 
                    <PaymentCashContent airBook={this.props.airBook} 
                        onChangeCreditLimitCheckbox={this.props.onChangeCreditLimitCheckbox}
                        onChangeCreditLimitAuthorizedPerson={this.props.onChangeCreditLimitAuthorizedPerson}
                        isFromBusiness={this.props.isFromBusiness}
                    /> 
                    : null}
                {this.props.optionSelected === TRAVEL_PAYMENTS ? 
                    <TravelPaymentContent /> : null}
            </div>
        )
    }
}

export default PaymentContent;