import React, { Component } from "react";

import generalErrorIcon from "../../assets/images/icons/general_error.png";
import duplicatedBookingIcon from "../../assets/images/icons/duplicated_booking.png";
import segmentsCancelledIcon from "../../assets/images/icons/segments_cancelled.png";
import fareChangedIcon from "../../assets/images/icons/fare_changed.png";
import "../../assets/stylesheets/Error.css";

class Error extends Component {

    getErrorIcon = () => {
        switch (this.props.code) {
            case "1000":
                return duplicatedBookingIcon;
            case "1001":
                return segmentsCancelledIcon;
            case "1002":
                return fareChangedIcon;
            case "1003":
                return generalErrorIcon;
            case "1004":
                return segmentsCancelledIcon;
            case "1010":
                return generalErrorIcon;
            default:
                return generalErrorIcon;
        }
    };

    render() {
        const title = this.props.title || "¡Ha ocurrido algo!";
        return (
            <div className="Error">
                <div className="Error__overlay"></div>
                <div className="Error__window">
                    <div className="Error__icon pt-5 pb-3">
                        <img src={this.getErrorIcon()} alt="Error icon" />
                    </div>
                    <div className="Error__content pl-4 pr-4 pb-5">
                        <h3 className="font-secondary text-2xl font-medium mb-3">
                            {title}
                        </h3>
                        <p className="text-gray-600">{this.props.message}</p>
                        <div className="Error__actions">
                            <button className="button" onClick={() => this.props.onClose()}>
                                Aceptar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}



export default Error;