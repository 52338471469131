import React, { Component } from "react";

class Loading2 extends Component {
    render() {
        return(
            <div className="spinner-container">
                <div className="spinner-square">
                </div>
                <p style={{width: "320px", textAlign: "center"}}> {this.props.title} </p>
            </div>
        )
    }
}

export default Loading2;