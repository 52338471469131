import React, { Component } from "react";

class PaymentCashContent extends Component {

    render() {


      return (
        <div className="mt-3 mb-3 pt-0 pb-0 font-weight-bold">
          Paga con cash
        </div>
      )
    }
}


export default PaymentCashContent;