import React, { Component } from "react";

const steps = [
    "1. Selecciona tu seguro",
    "2. Completa tus datos",
    "3. Finaliza tu reserva"
]

class BookingProgress extends Component {

    renderIconByStep(step) {
        switch (step) {
            case 0:
                return <i className="fa fa-search" aria-hidden="true"></i>
            case 1:
                return <i className="fa fa-info" aria-hidden="true"></i>
            case 2:
                return <i className="fa fa-shopping-cart" aria-hidden="true"></i>
            default:
                break;
        }
    }

    render = () => {
        return (
            <div className="BookingProgress pt-3 pb-3">
                <div className="row">
                    <div className="col">
                        {steps.map((step, i) => {
                            let className = "float-left font-secondary cursor-default";
                            if (i === this.props.activeStep) {
                                className += " font-bold text-gray-800";
                            } else {
                                className += " font-light text-gray-500";
                            }
                            return (
                                <li key={i} className={` ${className} col col-md-4 d-flex`}>
                                    <div className="step-circle">
                                        {this.renderIconByStep(i)}
                                    </div>
                                    {step}
                                    {i < 2 ? (
                                        <i className="fas fa-chevron-right text-sm mr-2 ml-2 mt-1" />
                                    ) : null}
                                </li>
                            )
                        })

                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default BookingProgress;