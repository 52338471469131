import React, { Component } from 'react';

class NiubizCardButtonForm extends Component {

    render() {
        return(
            <div>NiubizCardButtonForm</div>
        )
    }
}

export default NiubizCardButtonForm;