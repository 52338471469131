import React , { Component } from 'react';
import '../../assets/stylesheets/Footer.css';

class Footer extends Component {
    render() {
        return(
            <footer className="Footer" style={{minHeight: "300px", maxHeight: "auto"}}>
                <div className="footer-content light-bg-top">
                    <div className="pt-0 pb-0"
                        style={{
                            background: ``
                          }}
                    >
                        <div className="container">
                            <div className="row">
                                <div className="col-sm-6 col-md-4 mb-3">
                                    <h4 className="h6 text-white font-weight-bold mb-2">Nosotros</h4>
                                    <ul className="list-unstyled footer-link">
                                        <li><a href="https://pasajesbaratos.com/libro-reclamaciones" rel="noreferrer" target="_blank" className="text-white">Libro de reclamaciones</a></li>
                                        <li><a href="https://pasajesbaratos.com/terminos-condiciones" rel="noreferrer" target="_blank" className="text-white">Términos y condiciones</a></li>
                                        <li><a href="https://pasajesbaratos.com/politicas-uso-cookies" rel="noreferrer" target="_blank" className="text-white">Políticas de Uso de Cookies</a></li>
                                        <li><a href="https://pasajesbaratos.com/politicas-proteccion-datos" rel="noreferrer" target="_blank" className="text-white">Políticas de Protección de Datos</a></li>
                                    </ul>
                                </div>

                                <div className="col-sm-6 col-md-4 mb-3 col-sm-4">
                                    <h4 className="h6 text-white font-weight-bold mb-2">¿Cómo podemos ayudarte?</h4>
                                       
                                        <div className="ml-5 mt-4 book-mobile">
                                            <a href="https://pasajesbaratos.com/libro-reclamaciones"
                                                target="_blank"
                                                rel="noreferrer">
                                                <img src="https://costamar.com.pe/static/media/claims.2f94455a.png" 
                                                alt="Libro de reclamaciones"
                                                width="100px"/>
                                            </a>
                                        </div>
                                </div>

                                <div className="col-sm-6 col-md-4 mb-3">
                                    <address>
                                        <h4 className="h6 text-white font-weight-bold mb-2">Contáctenos</h4>
                                        <p className="text-white">
                                            E-mail: info@costamar.com
                                        </p>
                                        <p className='text-white'>
                                            © Costamar 2024. All rights reserved.
                                        </p>
                                    </address>
                                </div>

                            </div>
                            
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
    
    
}

export default Footer