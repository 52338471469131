import { 
    FILL_PASSENGER_FORM,
    FILL_CONTACT_FORM,
    FILL_PAYMENT_CREDIT_CARD_FORM,
    FILL_COMMISSION_AGENCY
} from "../actions/types";

const initialState = {
    passengers: [],
    contact: {
        firstName: null,
        lastName: null,
        email: null,
        countryCode: "PE+51",
        phoneNumber: null,
        ruc: null,
        companyName: null,
        companyAddress: null
    },
    paymentCreditCard: {
        cardNumber: null,
        cardType: null,
        expirationDate: null,
        securityCode: null,
        firstName: null,
        lastName: null,
        email: null,
        telephone: null,
        countryCode: "US",
        city: null,
        state: null,
        zipCode: null,
        address: null,
        isValidBean: null,
        documentType: null,
        docNumber: null
    },
    commissionAgency: 0
}

export default function (state = initialState, action) {
    
    switch (action.type) {
        case FILL_PASSENGER_FORM:
            let { passengers } = state;
            let passenger = action.passenger;
            passengers[passenger.index] = passenger;
            console.log("store ", state);
            return Object.assign({}, state, {
                passengers
            })
        case FILL_CONTACT_FORM:
            let contact = Object.assign({}, state.contact, action.payload);
            console.log("contact ", contact);
            console.log("store ", state);
            return Object.assign({}, state, {
                contact
            })
        case FILL_PAYMENT_CREDIT_CARD_FORM:
            let paymentCreditCard = Object.assign({}, state.paymentCreditCard, action.payload);
            return Object.assign({}, state, {
                paymentCreditCard
            });
        case FILL_COMMISSION_AGENCY:
            const commissionAgency = action.payload;
            return Object.assign({}, state, {
                commissionAgency: commissionAgency
            });
        default:
            return state;
    }
}