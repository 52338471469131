// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.wrapper {
    display: inline-block;
    padding: 1px 0;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    width: 16%; }
  
  div.wrapper a {
    width: 70%;
    padding: 1px;
    border: 3px solid #fff;
    display: block; }
  
  div.wrapper a.selected {
    outline-color: #fff;
    border-color: #4380c6;
    border-radius: 8px; }
  
  div.wrapper-img {
    position: relative; }
  
  div.wrapper img {
    width: 100%;
    display: block;
    max-width: 130px;
    margin: 0 auto;
    cursor: pointer; }
  
  .mb-10 {
    margin-bottom: 10px; }
  
  .mr-30 {
    margin-left: 30px; }
  
  .mr-50 {
    margin-left: 50px; }
  
  ul.list {
    list-style: inside !important; }
  
  ol.list {
    list-style-type: decimal !important; }
  `, "",{"version":3,"sources":["webpack://./src/assets/stylesheets/TravelPayment.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,cAAc;IACd,kBAAkB;IAClB,iBAAiB;IACjB,kBAAkB;IAClB,UAAU,EAAE;;EAEd;IACE,UAAU;IACV,YAAY;IACZ,sBAAsB;IACtB,cAAc,EAAE;;EAElB;IACE,mBAAmB;IACnB,qBAAqB;IACrB,kBAAkB,EAAE;;EAEtB;IACE,kBAAkB,EAAE;;EAEtB;IACE,WAAW;IACX,cAAc;IACd,gBAAgB;IAChB,cAAc;IACd,eAAe,EAAE;;EAEnB;IACE,mBAAmB,EAAE;;EAEvB;IACE,iBAAiB,EAAE;;EAErB;IACE,iBAAiB,EAAE;;EAErB;IACE,6BAA6B,EAAE;;EAEjC;IACE,mCAAmC,EAAE","sourcesContent":["div.wrapper {\n    display: inline-block;\n    padding: 1px 0;\n    text-align: center;\n    margin-left: auto;\n    margin-right: auto;\n    width: 16%; }\n  \n  div.wrapper a {\n    width: 70%;\n    padding: 1px;\n    border: 3px solid #fff;\n    display: block; }\n  \n  div.wrapper a.selected {\n    outline-color: #fff;\n    border-color: #4380c6;\n    border-radius: 8px; }\n  \n  div.wrapper-img {\n    position: relative; }\n  \n  div.wrapper img {\n    width: 100%;\n    display: block;\n    max-width: 130px;\n    margin: 0 auto;\n    cursor: pointer; }\n  \n  .mb-10 {\n    margin-bottom: 10px; }\n  \n  .mr-30 {\n    margin-left: 30px; }\n  \n  .mr-50 {\n    margin-left: 50px; }\n  \n  ul.list {\n    list-style: inside !important; }\n  \n  ol.list {\n    list-style-type: decimal !important; }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
