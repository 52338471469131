import React, { Component } from "react";

import { fillCommissionAgency } from '../../actions/bookFormActions';
import { connect } from "react-redux";

class InsurancePriceInformation extends Component {

    constructor(props) {
        super(props);
        this.state = {
            commissionAgency : 0
        }
    }

    saveCommissionAgency = () => {
        this.props.fillCommissionAgency(this.state.commissionAgency);
    }

    render() {
        return(
            <div className="card mt-5">
                <div className="card-body">
                   <div className="row">
                        <div className="col-12"><p className="font-weight-bold ">Cargos adicionales</p></div>
                   </div>
                   <div className="row text-muted">
                        <div className="col-12"><span className="small">Puede agregar un cargo adicional a este producto de seguro</span></div>
                   </div>
                   <div className="row mt-3">
                        <div className="col-md-8 col-12">
                            <input className="form-control" value={this.state.commissionAgency}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    this.setState({ commissionAgency : value })
                                }}
                            />
                        </div>
                        <div className="col-md-4 col-12 p-0">
                            <button className="btn btn-price-information rounded-sm"
                                onClick={this.saveCommissionAgency}
                            >Aceptar
                            </button>
                        </div>
                   </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = ({ bookFormReducer}) => {
    return {
        bookFormReducer
    }
}

export default  connect(mapStateToProps, { fillCommissionAgency })(InsurancePriceInformation);