import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getTransactionById } from '../../actions/bookingActions';
import BookingProgress from '../commons/BookingProgress';
import Loading from '../commons/Loading';
import LoadingProgress from '../commons/LoadingProgress';
import Navbar from '../commons/Navbar';
import BookingDetails from './BookingDetails';
import PaymentDetails from './PaymentDetails';
import axios from 'axios';
import Loading2 from '../commons/Loading2';
import { CASH } from '../../constants/paymentOptionsIDConstants';
import { BASE_URL } from '../../config/keys';

class BookingResult extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedTabIndex: 0,
            data: null,
            isLoading: true,
            isCash: false
        }
    }

    async componentDidMount() {
        const requestConfig = { withCredentials: true };
        const redisID = window.location.pathname.substring(16);
        const res = await axios.get(`${BASE_URL}/api/sessions?id=${redisID}`,requestConfig);
        const data = res.data.value;
        if(this.isCash(data)) {
            this.setState({ data: data, isLoading: false, selectedTabIndex: 1, isCash: true });
        }
        this.setState({ data: data, isLoading: false });
    }

    renderBookingResult(data) {
        return (
            <div className='Booking'>
                <LoadingProgress activeStep={2} />
                <div className='container'>
                    <BookingProgress activeStep={2} />
                </div>
                <div className='container mt-3'>
                    {this.renderAlertMessaggesBooking()}
                    {this.renderHeaderBooking(data)}
                    {this.renderContentBooking(data)}
                </div>
            </div>
        )
    }

    renderErrorBookingResult() {
        return (
            <div>No se pudo recuperar la información de su registro de seguro.</div>
        )
    }

    renderAlertMessaggesBooking() {
        return (
            <div className='row'>
                <div className='col-12'>
                    <div className='alert alert-success text-left borderless alert-dismissible'>
                        <button className='close'>
                            <span>x</span>
                        </button>
                        Gracias por confiar en nosotros. A continuación le
                        brindaremos la información necesaria para poder
                        continuar con su compra.
                    </div>
                </div>
                <div className='col-12'>
                    <h2 className='font-secondary text-2xl font-gray-800 font-semibold text-left'>
                        ¡Felicidades! se ha completado correctamente su reserva
                    </h2>
                </div>
            </div>
        )
    }

    renderHeaderBooking(data) {
        return (
            <div className='row mt-3 mb-4'>
                    <div className='col-md-12 Booking__code'>
                        Código de registro:
                        <span 
                            className="ml-3 pt-2 pb-2 pl-3 pr-3 rounded text-2xl bg-white font-secondary font-semibold shadow cursor-pointer"
                            style={{color: "rgba(49,130,206,1)", fontSize: "1.5rem", fontWeight: "600"}}
                        >
                            {data.registerRS.code}
                        </span>
                    </div>
            </div>
        )
    }

    isCash(data) {
        let isCash = false;
        const paymentsWithCash = [CASH];
        if(data && data.registerRS && data.registerRS.providerCode && paymentsWithCash.includes(data.registerRS.providerCode)) {
           isCash = true;
        }
        return isCash;
    }

    renderContentBooking(data) {
        const { selectedTabIndex } = this.state;
        return (
            <div className='row Booking__content mt-3'>
                <div className='col-md-12'>
                    <div className='tabs is-centered'>
                        <ul>
                            {this.state.isCash ? null :
                                <li
                                    className={
                                        `ml-3 mb-2 ${this.state.selectedTabIndex === 0 ? "is-active" : ""}`
                                    }
                                >
                                    <a
                                        className="font-secondary text-lg"
                                        onClick={() => { this.setState({ selectedTabIndex: 0 }) }}

                                    >
                                        Información del pago
                                    </a>
                                </li>}
                            <li
                                className={
                                    `ml-3 mb-2 ${this.state.selectedTabIndex === 1 ? "is-active" : ""}`
                                }
                            >
                                <a
                                    className="font-secondary text-lg"
                                    onClick={() => this.setState({ selectedTabIndex: 1 })}
                                >
                                    Detalles del Seguro
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className='col-md-12'>
                    <div
                        style={{ display: `${selectedTabIndex === 0 ? "block" : "none"}` }}
                    >
                        <PaymentDetails data={data} />
                    </div>
                    <div
                        style={{ display: `${selectedTabIndex === 1 ? "block" : "none"}` }}
                    >
                        <BookingDetails data={data} />
                    </div>
                </div>
            </div>
        )
    }

    render() {
        return (
            <div>
                {this.state.isLoading ?
                    (<Loading2 title={"Estamos recuperando información de su registro de seguro"}/>) :
                    (this.state.data ?
                        this.renderBookingResult(this.state.data) :
                        this.renderErrorBookingResult()
                    )}
            </div>
        )
    }
}

const mapStateToProps = ({ bookingReducer }) => {
    return {
        bookingReducer
    }
}

export default connect(mapStateToProps, { getTransactionById })(BookingResult);