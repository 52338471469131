import {
    GET_TOKEN_BY_NIUBIZ,
    GET_TRANSACTION_BY_ID,
    BOOKING_COMPLETE,
    BOOKING_ERROR
} from "../actions/types";

const initialState = {
    transaction: null,
    isLoading: true,
    niubizToken: null,
    transactionId: null,
    statusCode: null,
    error: null
}

const errorMessages = {
    1000: {
        title: "Reserva duplicada",
        message: "Nuestro sistema detecta que ya existe una reserva con los mismos datos del pasajero, por favor para poder asistirle, envíenos un correo a reservasweb@costamar.com.pe con el subject o asunto: Reserva Duplicada, en el cuerpo del correo detalle los nombres de los pasajeros, el correo con el cual generó la reserva, ruta y fechas que esta tomando, además un número de teléfono para poder contactarlo. Gracias."
    },
    1002: {
        title: "Cambio de tarifa",
        message: "Lo sentimos, al parecer este vuelo tuvo modificaciones en su tarifa. Intente con otros vuelos"
    },
    1003: {
        title: "Reserva inconclusa",
        message: "Ha ocurrido algo inesperado al momento de reservar tu vuelo. Intente nuevamente por favor"
    },
    1004: {
        title: "Reserva inconclusa",
        message: "Ha ocurrido algo inesperado al momento de reservar tu vuelo. Intente nuevamente por favor"
    },
    1010: {
        title: "Reserva inconclusa",
        message: "Ha ocurrido algo inesperado al momento de reservar tu vuelo. Intente nuevamente por favor"
    }
}

function getError(errorCode) {
    let error = errorMessages[errorCode];
    if(!error) {
          return {
            title: "Advertencia: No se pudo completar la reserva",
            message: "Por favor, intentar con otro producto"
          }
    }
    return error;
}

export default function (state = initialState, action) {
    switch (action.type) {
        case BOOKING_COMPLETE:
            return Object.assign({}, state, action.payload);
        case BOOKING_ERROR:
            const error = getError(action.payload.status);
            return Object.assign({},
                {
                    ...state,
                    error: error
                });
        case GET_TRANSACTION_BY_ID:
            console.log('getTransactionById', action.payload)
            const transaction = action.payload;
            return Object.assign({},
                {
                    ...state,
                    transaction: transaction,
                    isLoading: false
                });
        case GET_TOKEN_BY_NIUBIZ:
            console.log('getTokenByNiubiz', action.payload)
            const niubizToken = action.payload;
            return Object.assign({},
                {
                    ...state,
                    niubizToken: niubizToken
                })
        default:
            return state;
    }
}
