import { 
    SEARCH_INSURANCES_RECOMMENDATIONS, 
    SEND_PARAMS_SEARCH,
    SEARCH_INSURANCE_BY_ID, 
    SELECT_INSURANCE
} from "../actions/types";

const initialState = {
    insurances : [],
    searchParams: null,
    isLoadingRecommendations : true,
    insuranceSelected : null
}

function findInsuranceById(insurances, id) {
    console.log('findInsuranceById', insurances);
    console.log('id', id)
    const insurancesFiltered = insurances.filter(insurance => {
        return(insurance.product.idProduct === id) 
    });
    return insurancesFiltered[0];
}

//agregar luego el nombre de la funcion
export default  function insurancesReducerFuntion (state = initialState, action) {
    switch (action.type) {
        case SEARCH_INSURANCES_RECOMMENDATIONS:               
            let recommendations = action.payload.recommendations;                
        return Object.assign({},
            {
                ...state,
                recommendations : recommendations,
                insurances : recommendations,
                isLoadingRecommendations : false
            });
        
        case SEND_PARAMS_SEARCH:
            console.log('llamando a SEND_PARAMS_SEARCH REDUCEEEER', state)
            return Object.assign({},
                {
                    ...state,
                    searchParams : action.payload
                })
    
        case SELECT_INSURANCE:
            return Object.assign({}, state, action.payload)
        default:
            return state;
    }
}