import React, { Component } from "react";
import moment from "moment";
import { FaCheckCircle, FaPlaneArrival, FaPlaneDeparture, FaRegCalendarAlt, FaUserAlt } from "react-icons/fa";
import DatePicker from "react-datepicker";

import enginesUtils from "../../utils/enginesUtils";
import destinations from '../../data/destinations.json';
import { AGES_PARAM_INDEX, DEFAULT_TERMINAL_ID, DESTINATION_INDEX_PARAM_INDEX, DESTINATION_PARAM_INDEX, END_DATE_PARAM_INDEX, ORIGIN_PARAM_INDEX, START_DATE_PARAM_INDEX } from "../../constants/searchParamConstants";

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

class InsurancesEnginesRecotize2 extends Component {

    constructor(props) {
        super(props);
        const { statePrev } = props;
        this.state = {
            origin: "PERU",
            destination: statePrev[DESTINATION_PARAM_INDEX],
            destinationName: null,
            startDate: moment(statePrev[START_DATE_PARAM_INDEX]).valueOf(),
            endDate: moment(statePrev[END_DATE_PARAM_INDEX]).valueOf(),
            age1: null,
            age2: null,
            age3: null,
            age4: null,
            age5: null,
            age6: null,
            age7: null,
            age8: null,
            age9: null,
            age10: null,
            isOpenAlert: false,
            paxQuantity: statePrev[AGES_PARAM_INDEX].split(",").length,
            agesInputArray: statePrev[AGES_PARAM_INDEX].split(","),
            isFormValid: false,
            openPassengerModal: false
        }
    }

    componentDidMount() {
        this.setStatePrev(this.props.statePrev);
    }

    setStatePrev(statePrev) {
        //aqui seteamos valores del estado previo
        //para la recotizacion
        let destinationName = enginesUtils.getDestinationValueByDestinationIndex(this.props.statePrev[DESTINATION_INDEX_PARAM_INDEX]);
        const agesInputArray = statePrev[AGES_PARAM_INDEX].split(",");
        const paxQuantity = agesInputArray.length;
        const text1 = statePrev[DESTINATION_PARAM_INDEX];
        this.setState({
            origin: statePrev[ORIGIN_PARAM_INDEX],
            destination: destinationName,
            destinationName,
            startDate: moment(statePrev[START_DATE_PARAM_INDEX]).valueOf(),
            endDate: moment(statePrev[END_DATE_PARAM_INDEX]).valueOf(),
            paxQuantity: paxQuantity,
            agesInputArray: agesInputArray
        });
    }

    openModal = () => {
        this.setState({ openPassengerModal: true })
    }

    onClickModal = () => {
        const newOpenPassengerModal = !this.state.openPassengerModal;
        this.setState({ openPassengerModal: newOpenPassengerModal });
    }

    closeModal = () => {
        this.setState({ openPassengerModal: false })
    }

    onChange = e => {
        let value = e.target.value;

        this.setState({
            [e.target.name]: value
        })
    }

    handleStartDateChange(date) {
        let dateFinally = moment(date).format("YYYY-MM-DD");
        this.setState({ startDate: date })
    }

    handleEndDateChange(date) {
        let dateFinally = moment(date).format("YYYY-MM-DD");
        this.setState({ endDate: date })
    }

    onChangeAgesArray = e => {
        const { agesInputArray } = this.state;
        if (agesInputArray.length === 0) {
            agesInputArray.push(e.target.value);
            this.setState({ agesInputArray: agesInputArray })
        } else {
            if (agesInputArray[e.target.name]) {
                if(e.target.value === "" || e.target.value === " ") {
                    agesInputArray.pop();
                } else {
                    agesInputArray[e.target.name] = e.target.value;
                }
            } else {
                agesInputArray.splice(e.target.name, 0, e.target.value)
            }
            this.setState({ agesInputArray: agesInputArray })
        }
    }

    onSubmit = e => {
        e.preventDefault()

        const { hasCompleteFields, pathParams } = enginesUtils.hasCompleteFieldsByState(this.state);
        if (hasCompleteFields) {
            const terminalId = window.location.search && window.location.search.length > 11 ?  window.location.search.substring(12) : DEFAULT_TERMINAL_ID;
            let pathStringArray = `${pathParams.join("/")}?terminalId=${terminalId}`;
            window.location.href = window.location.origin + "/" + pathStringArray;

        } else {
            window.Swal.fire({
                title: 'Advertencia',
                text: 'Datos de búsqueda incompletos',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'OK'
            });
        }

    }

    render() {
        const { statePrev } = this.props;
        const origin = statePrev[ORIGIN_PARAM_INDEX];
        const destination = enginesUtils.getDestinationNameByValue(statePrev[DESTINATION_PARAM_INDEX]);
        const startDate = statePrev[START_DATE_PARAM_INDEX];
        const endDate = statePrev[END_DATE_PARAM_INDEX];
        const ages = statePrev[AGES_PARAM_INDEX];

        const agesArray = ages.split(",");

        const coverageDays = enginesUtils.calculateElapsedDays(startDate, endDate);
        return (
            <div className="row p-2 SearchParamsInformation container ml-auto mr-auto">
                <div className="col-md-4 col-6">
                    <div className="d-flex"> <FaPlaneArrival className="mr-3 mt-auto mb-auto w-25"/>
                        <select className="form-control select"
                            id="destination"
                            name='destination'
                            value={this.state.destination}
                            onChange={this.onChange}
                            defaultValue={this.state.destination}
                        >
                            {destinations.map(dest => (
                                <option key={dest.value}
                                    value={dest.value}
                                    defaultValue={this.state.destination ?
                                        this.state.destination : dest.value}
                                >
                                    {dest.name}
                                </option>
                            ))

                            }
                        </select>
                    </div>
                </div>
                <div className="col-md-2 col-6 d-flex">
                    <FaRegCalendarAlt className="mr-3 mt-auto mb-auto w-25" />
                    <DatePicker
                        selected={this.state.startDate}
                        onChange={(date) => this.handleStartDateChange(date)}
                        minDate={new Date()}
                        className="form-control select"
                        placeholderText="Fecha salida"
                        value={moment(this.state.startDate).format('DD/MM/YYYY')}
                    />
                </div>
                <div className="col-md-2 col-6 d-flex">
                    <FaRegCalendarAlt className="mr-3 mt-auto mb-auto w-25" />
                    <DatePicker
                        selected={this.state.endDate}
                        onChange={(date) => this.handleEndDateChange(date)}
                        minDate={this.state.startDate}
                        className="form-control select"
                        placeholderText="Fecha regreso"
                        value={moment(this.state.endDate).format('DD/MM/YYYY')}
                    />
                </div>
                <div className='col-md-2 col-6 mt-auto d-flex'>
                    <FaUserAlt className="mr-3 mt-auto mb-auto w-25" />
                    <input type="button" className='form-control'
                        onClick={() => {
                            //this.openModal();
                            this.onClickModal()
                        }}
                        value={`${this.state.paxQuantity} ${this.state.paxQuantity > 1 ? 'Pasajeros' : 'Pasajero'}`}
                    />
                </div>
                {/*<div className="col-md-4 col-6">
                    <div className="d-flex">
                        <div><FaUserAlt />
                            <i
                                className={`btn fa fa-minus-circle text-white  ${this.state.paxQuantity <= 1 ? 'disabled' : ''}`}
                                onClick={() => {
                                    if (this.state.paxQuantity > 1) {
                                        const agesInputArray = this.state.agesInputArray.pop();
                                        this.setState({ paxQuantity: this.state.paxQuantity - 1 })
                                    }
                                }}
                                aria-hidden="true">
                            </i>
                            <input name='paxQuantity' className='form-control'
                                value={`${this.state.paxQuantity} ${this.state.paxQuantity > 1 ? 'pasajeros' : 'pasajero'}`} />
                            <i
                                className={`btn fa fa-plus-circle text-white ${this.state.paxQuantity >= 8 ? 'disabled' : ''}`}
                                onClick={() => {
                                    if (this.state.paxQuantity < 8) {
                                        this.setState({ paxQuantity: this.state.paxQuantity + 1 })
                                    }
                                }}
                                aria-hidden="true">
                            </i>
                        </div>
                    </div>
                </div>*/}
                {/*[...Array(this.state.paxQuantity)].map((e, index) => {
                    return (
                        <div key={index} className='col-1 form-group'>
                            <input key={index}
                                className="form-control"
                                name={index}
                                onChange={this.onChangeAgesArray}
                                value={this.state.agesInputArray.length > 0 && this.state.agesInputArray[index]
                                    ? this.state.agesInputArray[index] : ""}
                            />
                        </div>
                    )
                })*/}
                <div className="col-md-2 col-6 mt-auto">
                    <button
                        className='btn btn-info quote-button btn-recotize'
                        value="cotizar"
                        onClick={this.onSubmit}
                        disabled={!enginesUtils.hasCompleteFieldsByState(this.state).hasCompleteFields}
                    >Recotizar</button>
                </div>
                {
                    this.state.openPassengerModal ?
                        (
                            <div className="col-md-5 card ml-auto mt-3 p-3" style={{ textAlign: "end" }}>

                                <Modal show={this.state.openPassengerModal} onHide={this.closeModal} backdrop="static"
                                    keyboard={false} scrollable={true}>
                                    <Modal.Body>
                                        <div className='card-title mb-0'>
                                            <div className='form-group col-md-10'>
                                                <div className='d-flex'>
                                                    <div className={`btn InsurancesEngine_passengers_button  ${this.state.paxQuantity <= 1 ? 'disabled' : ''}`}
                                                        onClick={() => {
                                                            if (this.state.paxQuantity > 1) {
                                                                const agesInputArray = this.state.agesInputArray.pop();
                                                                this.setState({ paxQuantity: this.state.paxQuantity - 1 })
                                                            }
                                                        }}>-</div>
                                                    <input name='paxQuantity' className='form-control mr-3 ml-3'
                                                        value={`${this.state.paxQuantity} ${this.state.paxQuantity > 1 ? 'pasajeros' : 'pasajero'}`} />
                                                    <div className={`btn InsurancesEngine_passengers_button ${this.state.paxQuantity >= 8 ? 'disabled' : ''}`}
                                                        onClick={() => {
                                                            if (this.state.paxQuantity < 8) {
                                                                this.setState({ paxQuantity: this.state.paxQuantity + 1 })
                                                            }
                                                        }
                                                        }>+</div>
                                                    <div className='ml-3'>
                                                        <Button variant="primary"
                                                            onClick={this.closeModal}
                                                        >
                                                            OK
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='card-body pt-1'>
                                            <div className='row ml-0'>
                                                <div className='form-group col-12'>
                                                    <div className='row'>
                                                        {[...Array(this.state.paxQuantity)].map((e, index) => {
                                                            return (
                                                                <div className='d-flex mt-2'>
                                                                    <label className='mr-3'>Edad del pasajero {index + 1}</label>
                                                                    <input key={index}
                                                                        className="form-control col-2 mr-3"
                                                                        name={index}
                                                                        onChange={this.onChangeAgesArray}
                                                                        value={this.state.agesInputArray.length > 0 ?
                                                                            this.state.agesInputArray[index] : ""}
                                                                    />
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Modal.Body>
                                </Modal>
                            </div>
                        ) : null
                }
            </div>
        )
    }
}

export default InsurancesEnginesRecotize2;