import {
    GET_CHECKOUT_DATA
} from '../actions/types';

const initialState = {
    data: null,
    loading: true
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_CHECKOUT_DATA:
            return Object.assign({}, state, {
                data: action.payload,
                loading: false
            })    
        default:
            return state;
    }
}