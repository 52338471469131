import React, { Component } from 'react';

class AcuotazFrame extends Component {
    render() {
        return(
            <div>AcuotazFrame</div>
        )
    }
}

export default AcuotazFrame;