import React, { Component } from 'react';
import moment from "moment";
import { connect } from 'react-redux';
import { sendParamsToSearch } from '../../actions/insurancesActions';
import DatePicker from "react-datepicker";
import destinations from '../../data/destinations.json';
import enginesUtils from '../../utils/enginesUtils';
//import SweetAlert from 'sweetalert2-react';
import {
    FaRegCalendarAlt,
    FaPlaneArrival
} from 'react-icons/fa';


import "react-datepicker/dist/react-datepicker.css";
import "../../assets/stylesheets/Engines.css";
import { AGES_PARAM_INDEX, DEFAULT_TERMINAL_ID, DESTINATION_INDEX_PARAM_INDEX, DESTINATION_PARAM_INDEX, END_DATE_PARAM_INDEX, ORIGIN_PARAM_INDEX, START_DATE_PARAM_INDEX } from '../../constants/searchParamConstants';

class InsurancesEngineRecotize extends Component {

    constructor(props) {
        super(props);
        const { statePrev } = props;
        this.state = {
            origin: "PERU",
            destination: statePrev[DESTINATION_PARAM_INDEX],
            destinationName: null,
            destinationIndex: statePrev[DESTINATION_INDEX_PARAM_INDEX],
            startDate: null,
            endDate: null,
            age1: null,
            age2: null,
            age3: null,
            age4: null,
            age5: null,
            age6: null,
            age7: null,
            age8: null,
            age9: null,
            age10: null,
            isOpenAlert: false,
            paxQuantity: 1,
            agesInputArray: statePrev[AGES_PARAM_INDEX].split(","),
            isFormValid: false
        }
    }

    componentDidMount() {

        console.log('componentDidMount INSURANCE ENGINE RECOTIZE', this.props);

        if (this.props.isRequote) {
            //recotization state inicial

            this.setStatePrev(this.props.statePrev)

        }
    }

    setStatePrev(statePrev) {
        //aqui seteamos valores del estado previo
        //para la recotizacion
        let destinationName = enginesUtils.getDestinationValueByDestinationIndex(this.props.statePrev[DESTINATION_INDEX_PARAM_INDEX]);
        const agesInputArray = statePrev[AGES_PARAM_INDEX].split(",");
        const paxQuantity = agesInputArray.length;
        const text1 = statePrev[DESTINATION_PARAM_INDEX];
        this.setState({
            origin: statePrev[ORIGIN_PARAM_INDEX],
            destination: destinationName,
            destinationName,
            startDate: moment(statePrev[START_DATE_PARAM_INDEX]).valueOf(),
            endDate: moment(statePrev[END_DATE_PARAM_INDEX]).valueOf(),
            paxQuantity: paxQuantity,
            agesInputArray: agesInputArray
        });
    }

    handleStartDateChange(date) {
        let dateFinally = moment(date).format("YYYY-MM-DD");
        this.setState({ startDate: date })
    }

    handleEndDateChange(date) {
        let dateFinally = moment(date).format("YYYY-MM-DD");
        this.setState({ endDate: date })
    }

    onChange = e => {
        let value = e.target.value;

        this.setState({
            [e.target.name]: value
        })
    }

    onChangeAgesArray = e => {
        const { agesInputArray } = this.state;
        //aqui lo agregamos al array agesInputArray con push
        if (agesInputArray.length === 0) {
            agesInputArray.push(e.target.value);
            this.setState({ agesInputArray: agesInputArray })
        } else {
            if (agesInputArray[e.target.name]) {
                agesInputArray[e.target.name] = e.target.value;
            } else {
                agesInputArray.splice(e.target.name, 0, e.target.value)
            }
            this.setState({ agesInputArray: agesInputArray })
        }
    }

    onSubmit = e => {
        e.preventDefault()

        const { hasCompleteFields, pathParams } = enginesUtils.hasCompleteFieldsByState(this.state);
        if (hasCompleteFields) {
            const terminalId = window.location.search && window.location.search.length > 11 ?  window.location.search.substring(12) : DEFAULT_TERMINAL_ID;
            let pathStringArray = `${pathParams.join("/")}?terminalId=${terminalId}`;
            window.location.href = window.location.origin + "/" + pathStringArray;

        } else {
            console.log('ADVERTENCIA: FALTAN COMPLETAR CAMPOS!!!')
            window.Swal.fire({
                title: 'Advertencia',
                text: 'Datos de búsqueda incompletos',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'OK'
            });
        }


    }



    render() {
        const { paxQuantity } = this.state;
        let textTitle = "Cotiza tu asistencia de viaje";
        let textButton = "Cotizar";
        if (this.props.isRequote) {
            textTitle = "Recotiza tu viaje";
            textButton = "Recotizar";
        }
        return (
            <div className='container-fluid pl-0 pr-0'>
                <div className='col-12 col-md-12 m-auto p-auto mt-3 mb-3 pl-0 pr-0 InsurancesEngineRecotize'
                >
                    <form className='p-3' style={{borderRadius: "0px 10px 10px 10px", }}>
                        <div className='' 
                        style={{display: 'flex', alignItems: "center", justifyContent: "space-between"}}>

                            <div className='form-group d-flex'>
                                <div><FaPlaneArrival className='text-white mr-2' /></div>
                                <select className="form-control select"
                                    id="destination"
                                    name='destination'
                                    value={enginesUtils.getDestinationValueByDestinationIndex(this.state.destinationIndex)}
                                    onChange={this.onChange}
                                >

                                    {destinations.map(dest => (
                                        <option key={dest.value}
                                            value={dest.value}
                                            defaultValue={this.state.destination ? 
                                                this.state.destination : dest.value}
                                            >
                                            {dest.name}
                                        </option>
                                    ))

                                    }
                                </select>
                            </div>
                            <div className='form-group d-flex'>
                                <div><FaRegCalendarAlt className='text-white mr-2' /></div>
                                <DatePicker
                                    selected={this.state.startDate}
                                    onChange={(date) => this.handleStartDateChange(date)}
                                    minDate={new Date()}
                                    className="form-control select"
                                    placeholderText="Fecha salida"
                                    style={{ cursor: "grab" }}
                                />
                            </div>
                            <div className='form-group d-flex'>
                                <div><FaRegCalendarAlt className='text-white mr-2' /></div>
                                <DatePicker
                                    selected={this.state.endDate}
                                    onChange={(date) => this.handleEndDateChange(date)}
                                    minDate={this.state.startDate}
                                    className="form-control select"
                                    placeholderText="Fecha regreso"
                                />
                            </div>
                            <div className='form-group'>
                                <div className='d-flex'>
                                    <i  
                                        className={`btn fa fa-minus-circle text-white  ${paxQuantity <= 1 ? 'disabled' : ''}`}
                                        onClick={() => {
                                            if (paxQuantity > 1) {
                                                const agesInputArray = this.state.agesInputArray.pop();
                                                this.setState({ paxQuantity: paxQuantity - 1 })
                                            }
                                        }}
                                        aria-hidden="true">
                                    </i>
                                    <input name='paxQuantity' className='form-control' 
                                        value={`${paxQuantity} ${paxQuantity > 1 ? 'pasajeros': 'pasajero'}`} />
                                    <i
                                        className={`btn fa fa-plus-circle text-white ${paxQuantity >= 8 ? 'disabled' : ''}`}
                                        onClick={() => {
                                            if (paxQuantity < 8) {
                                                this.setState({ paxQuantity: paxQuantity + 1 })
                                            }
                                        }}
                                        aria-hidden="true">
                                    </i>
                                </div>
                            </div>
                            {[...Array(this.state.paxQuantity)].map((e, index) => {
                                        return (
                                            <div key={index} className='col-1 form-group'>
                                                <input key={index}
                                                    className="form-control"
                                                    name={index}
                                                    onChange={this.onChangeAgesArray}
                                                    value={this.state.agesInputArray.length > 0 && this.state.agesInputArray[index] 
                                                        ? this.state.agesInputArray[index] : ""}
                                                />
                                            </div>
                                        )
                                    })}
                            <button
                                    className='btn btn-info quote-button btn-recotize mb-2'
                                    value="cotizar"
                                    onClick={this.onSubmit}
                                    disabled={!enginesUtils.hasCompleteFieldsByState(this.state).hasCompleteFields}
                                >{textButton}</button>
                        </div>

                    </form>

                    {this.state.isOpenAlert ? 'Datos incompletos' : null}
                </div >
            </div>
        )
    }
}


const mapStateToProps = ({ insurancesReducer }) => {
    return {
        searchParams: insurancesReducer.searchParams ? insurancesReducer.searchParams : null
    }
}

export default connect(mapStateToProps, { sendParamsToSearch })(InsurancesEngineRecotize);