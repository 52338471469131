//search
export const SEARCH_INSURANCES_RECOMMENDATIONS = 'search_insurances_recommendations';
export const SEND_PARAMS_SEARCH = 'send_params_search';
export const SELECT_INSURANCE = 'select_insurance';

//checkout
export const GET_CHECKOUT_DATA = 'get_checkout_data';

//bookForm
export const FILL_PASSENGER_FORM = 'fill_passenger_form';
export const FILL_CONTACT_FORM = 'fill_contact_form';
export const FILL_PAYMENT_CREDIT_CARD_FORM = 'fill_payment_credit_card_form';
export const FILL_COMMISSION_AGENCY = 'fill_commission_agency';
//booking
export const BOOKING_COMPLETE = 'booking_complete';
export const BOOKING_ERROR = 'booking_error';

//transaction
export const GET_TOKEN_BY_NIUBIZ = 'get_token_by_niubiz';
export const GET_TRANSACTION_BY_ID = 'get_transaction_by_id';

export const LOAD_ENGINE = 'load_engine';